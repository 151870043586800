<template>
  <div>
    <AppointmentFormCP
      v-if="isHasDisease"
      :disease="disease"
      :personData="personData"
      @nextStep="nextStep"
    />
    <AppointmentFormNCP v-else :personData="personData" @nextStep="nextStep" />
  </div>
</template>

<script>
import AppointmentFormNCP from './AppointmentFormNCP.vue'
import AppointmentFormCP from './AppointmentFormCP.vue'
export default {
  name: 'AppointmentForm',
  props: ['isHasDisease', 'disease', 'personData'],
  components: { AppointmentFormNCP, AppointmentFormCP },
  methods: {
    nextStep (params) {
      this.$emit('nextStep', params)
    }
  }
}
</script>

<style scoped></style>
