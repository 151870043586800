<template>
  <div>
    <div class="table-responsive">
      <table class="table table-vcenter table-mobile-md card-table table-scroll">
        <thead>
          <tr>
            <th class="pl-2">Thời gian1</th>
            <th class="pl-md-4">Thông tin bệnh nhân</th>
            <th class="pl-md-4">Dịch vụ</th>
            <th class="pl-md-4">{{type == 'my' ? 'Cơ sở y tế' : 'Bác sĩ'}}</th>
            <th
              v-if="type === 'clinic'"
              class="pl-md-4"
            >Tình trạng</th>
            <th
              class="pl-md-4"
              v-if="type == 'history-on'"
            >Thời gian gọi</th>
          </tr>
        </thead>
        <tbody
          v-show="appointments && appointments.count"
          id="appt_list"
        >
          <tr
            v-for="appointment in appointments.data"
            :key="appointment.id"
            class="cursor-pointer mt-2 mb-2 radius-10"
            @click="goToDetail(appointment)"
          >
            <td
              data-label="Lịch khám"
              style="text-transform: capitalize;"
              :set="[time= moment(appointment.start_time), end= moment(appointment.end_time)]"
            >
              <div class="d-flex align-items-center">
                <span class="robo-16-400 border-right pr-2 d-block">{{time.format('DD/MM/YY')}}</span>
                <span class="pl-2 robo-16-400 d-block">{{time.format('HH:mm')}} - {{end.format('HH:mm')}}</span>
              </div>
            </td>
            <td
              data-label="Thông tin bệnh nhân"
              class="table-td-name-avatar pl-2"
            >
              <div class="d-flex py-1 align-items-center">
                <div style="min-width: 40px;">
                  <span
                    v-if="hasAvatar(appointment)"
                    class="avatar avatar-sm avatar-rounded"
                    :style="'background-image: url('+getImageURL(appointment.person.user.avatar)+')'"
                  ></span>
                  <span
                    v-if="!hasAvatar(appointment)"
                    class="avatar avatar-sm avatar-rounded bg-secondary text-white"
                  >
                    {{ appointment.person && appointment.person.name ? appointment.person.name.charAt(0) : '' }}
                  </span>
                </div>
                <div class="flex-fill">
                  <p class="p-0 m-0 robo-16-400">{{appointment.person ? appointment.person.name : ''}}{{appointment.person ? ' - ' + appointment.person.phone : ''}}</p>
                </div>
              </div>
            </td>
            <td data-label="Dịch vụ">
              <div class="d-flex align-items-center">
                <img
                  v-if="appointment.method == 2"
                  src="../../../public/assets/images/icon/blue-video.svg"
                  alt=""
                  width="28"
                  height="28"
                >
                <img
                  v-if="appointment.method == 1"
                  src="../../../public/assets/images/icon/green-walking.svg"
                  alt=""
                  width="28"
                  height="28"
                >
                <span
                  class="robo-16-500 d-block ml-2"
                  :class="appointment.method == 1 ? 'text--green' : appointment.method == 2 ? 'txt-pri' : ''"
                >
                  {{appointment.method == 1 ? $t("workspace_setting.lbl_to_workspace")[$orgModel] : appointment.method == 2 ? 'Khám bệnh trực tuyến' : ''}}
                </span>
              </div>
            </td>
            <td>
              <span
                class="txt-pri robo-16-500 p-2 bg-bold-item px-3 radius-10 d-block text-center"
                v-if="type === 'my'"
              >{{appointment.clinic && appointment.clinic.name}}</span>
              <div
                class="d-flex py-1 align-items-center"
                v-if="type !== 'my'"
              >
                <div style="min-width: 40px;">
                  <span
                    v-if="hasAvatar(appointment)"
                    class="avatar avatar-sm avatar-rounded"
                    :style="'background-image: url('+getImageURL(appointment.doctor.user.avatar)+')'"
                  ></span>
                  <span
                    v-if="!hasAvatar(appointment)"
                    class="avatar avatar-sm avatar-rounded bg-secondary text-white"
                  >
                    {{ appointment.doctor && appointment.doctor.name ? appointment.doctor.name.charAt(0) : '' }}
                  </span>
                </div>
                <div class="flex-fill">
                  <p class="p-0 m-0 robo-16-400">{{appointment.doctor ? appointment.doctor.name : ''}}</p>
                </div>
              </div>
            </td>
            <td
              v-if="type === 'clinic'"
              data-label="Tình trạng"
              :set="[invoice = getInvoiceStatus(appointment), status = getApptStatus(appointment.status)]"
            >
              <span
                v-if="appointment.method == 2"
                class="pl-3 pr-3 pt-1 pb-1 radius-10 ml-2 d-block text-center"
                :class="invoice.class"
              >{{invoice.text}}</span>
              <span
                v-if="appointment.method == 1"
                class="pl-3 pr-3 pt-1 pb-1 radius-10 ml-2 d-block text-center"
                :class="status.class"
              >{{status.text}}</span>
            </td>
            <td
              data-label="Thời gian gọi"
              v-if="type == 'history-on'"
            >
              {{getCallTime(appointment)}}
            </td>
          </tr>
        </tbody>
        <tbody v-if="loading">
          <tr
            v-for="i in 5"
            :key="i"
          >
            <td>
              <div class="skeleton-avatar avatar-sm avatar-rounded">
              </div>
            </td>
            <td>
              <div class="skeleton-line"></div>
            </td>
            <td>
              <div class="skeleton-line"></div>
            </td>
            <td>
              <div class="skeleton-line"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="appointments && !appointments.count && !loading"
      class="row"
    >
      <div class="col-md-4 offset-md-4 pt-3 pb-5 text-center">
        <img
          src="../../../public/assets/images/not-found-search.png"
          class="img-responsive p-0"
          alt=""
        >
        <p class="robo-16-400 text-black">{{search ? 'HODO không tìm thấy lịch làm việc mà bạn tìm' : 'Chưa có lịch làm việc nào!' }}</p>
        <button
          v-if="!is_dashboard && can('create-new-appt')"
          class="btn-xl btn--blue d-none"
          type="button"
          @click="onAddNewAppt()"
        ><span class="fa fa-plus mr-2"></span> Thêm lịch làm việc</button>
      </div>
    </div>
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils.js'
export default {

  name: 'AppointmentTable',
  props: ['appointments', 'search', 'loading', 'is_dashboard', 'type'],
  data () {
    return {

    }
  },
  mounted () {
    // let self = this
    // let appt_list = document.querySelector('#appt_list')
    // appt_list.addEventListener('scroll', e => {
    //   if (Math.ceil(appt_list.scrollTop + appt_list.clientHeight) >= appt_list.scrollHeight) {
    //     if (self.appointments.count < self.appointments.total) {
    //       self.getAppointments(self.appointments.count + 10)
    //     }
    //   }
    // })
  },
  methods: {
    goToDetail (appt) {
      if (appt) {
        this.$router.push({ path: `/doctor/appointment/${appt.id}` })
      }
    },
    hasAvatar (appt) {
      return !!(appt && appt.person && appt.person.user && appt.person.user.avatar)
    },
    getImageURL (avatar) {
      return appUtils.getImageURL(avatar)
    },
    getApptStatus (stt) {
      let statuses = []
      statuses[1] = { class: 'text--yellow bg--yellow-corl', text: 'Chờ xác nhận' }
      statuses[2] = { class: 'text--green bg--green-corl', text: 'Xác nhận khám' }
      statuses[3] = { class: 'text--red bg--red-corl', text: 'Bác sĩ từ chối' }
      statuses[4] = { class: 'text--yellow bg--yellow-corl', text: 'Chờ xác nhân' }
      statuses[5] = { class: 'text--green bg--green-corl', text: 'Xác nhận khám' }
      statuses[6] = { class: 'text--red bg--red-corl', text: 'Bệnh nhân từ chối' }
      return statuses[stt] ? statuses[stt] : statuses[1]
    },
    getInvoiceStatus (appt) {
      if (!appt || !appt.invoice) return false
      let stt = appt.invoice.status
      let statuses = {}
      statuses[1] = { text: 'Chưa thanh toán', class: 'text--red bg--red-corl' }
      statuses[2] = { text: 'Chờ xác nhận', class: 'text--yellow bg--yellow-corl' }
      statuses[3] = { text: 'Đã thanh toán', class: 'text--green bg--green-corl' }
      return stt && statuses[stt] ? statuses[stt] : statuses[1]
    },
    formatPhoneNumber (number) {
      // Filter only numbers from the input
      let cleaned = ('' + number).replace(/\D/g, '')

      // Check if the input is of correct length
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

      if (match) {
        let hiden_number = ''
        for (var i = 0; i < match[2].length; i++) {
          hiden_number += 'X'
        }
        return match[1] + '-' + hiden_number + '-' + match[3]
      };

      return null
    },
    timeAgo (date_time) {
      let curr_week = window.moment().week()
      let date_time_week = window.moment(date_time).week()
      if (curr_week === date_time_week) {
        return window.moment(date_time).fromNow()
      } else {
        // return window.moment(date_time).format('HH:mm DD/MM/YYYY')
        var split_time = window.moment(date_time).format('DD/MM/YYYY HH:mm ').split(' ')
        var str_time = split_time[1] + '<br><small class="robo-12-400 text-muted">' + split_time[0] + '</small>'
        return str_time
      }
    },
    onAddNewAppt () {
      if (!this.can('create-new-appt')) return
      this.$emit('onAddNewAppt')
    },
    getAppointments (limit) {
      this.$emit('getAppointments', limit)
    },
    getCallTime (appt) {
      if (appt && appt.call_logs) {
        return this.getTimeFromSecond(appt.call_logs.map(item => item.answer_duration).reduce((prev, curr) => prev + curr, 0))
      }
    },
    getTimeFromSecond (sc) {
      return window.moment.utc(sc * 1000).format('HH:mm:ss')
    },
    moment (t) {
      return window.moment(t)
    }
  }
}
</script>
<style lang="css" scoped>
#appt_list tr td:last-child {
  background: url(../../../public/assets/images/icon/right.svg);
  background-position: 95% 50%;
  background-repeat: no-repeat;
  background-size: 7px 14px;
  padding-right: 30px !important;
}

td {
  color: #434343 !important;
}

.bg-bold-item {
  background-color: #e7eaf5;
}
#appt_list tr:hover {
  background-color: #e7eaf5;
}
</style>