<template>
  <el-dialog
    title="Phân công"
    :visible.sync="dialogVisible"
    width="50%"
    :before-close="handleClose"
    v-loading="loading"
  >
    <SelectDoctorCpn
      :disabled="loading"
      :filter="filterDoctors"
      @selectedDoctor="selectedDoctor"
    />
    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        class="bg-pri"
        :disabled="!selected_doctor || loading"
        @click="updateAppts"
        >Lưu</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import SelectDoctorCpn from '../Doctor/SelectDoctorCpn.vue'
export default {
  name: 'ModalAssignDoctorToAppt',
  components: { SelectDoctorCpn },
  data () {
    return {
      dialogVisible: false,
      cr_id: 0,
      person_diseases_id: 0,
      appt: null,
      selected_doctor: null,
      loading: false
    }
  },
  computed: {
    filterDoctors () {
      let params = {
        clinic_id: this.$globalClinic?.id
      }
      if (this.person_diseases_id) params.person_diseases_id = this.person_diseases_id
      if (this.cr_id) params.cr_id = this.cr_id
      return params
    }
  },
  methods: {
    handleClose () {
      this.cr_id = 0
      this.person_diseases_id = 0
      this.appt = null
      this.dialogVisible = false
    },
    show (stt, appt) {
      if (stt && appt) {
        this.appt = appt
        this.person_diseases_id = appt.person_diseases_id
        this.cr_id = appt.cr_id
      }
      this.dialogVisible = stt
    },
    async updateAppts () {
      let params = {
        note: this.appt.note,
        method: this.appt.method,
        person_id: this.appt.person_id,
        cr_id: this.appt.cr_id,
        person_diseases_id: this.appt.person_diseases_id,
        doctor_id: this.selected_doctor.id,
        start_time: this.appt.start_time,
        end_time: this.appt.end_time,
        slot_price: this.appt.slot_price,
        slot_duration: this.appt.slot_duration,
        clinic_id: this.appt.clinic_id,
        health_record_id: this.appt.health_record_id
      }
      this.loading = true
      await this.$rf.getRequest('DoctorRequest').updateAppts(this.appt.id, params).then((r) => {
        this.handleClose()
        this.$emit('refresh')
      }).finally(() => {
        this.loading = false
      })
    },
    selectedDoctor (d) {
      this.selected_doctor = d
    }
  }
}
</script>

<style scoped>
</style>