<template>
  <div>
    <el-dialog
      title="Chọn sổ khám bệnh"
      :visible.sync="dialogVisible"
      width="60%"
      height="70%"
    >
      <div class="row height-70vh">
        <div class="col-8 h-100p">
          <div
            class="hr-item"
            v-for="hr in healthRecords"
            :key="hr.id + 'hr'"
            :class="selected && selected.id === hr.id ? 'active' : ''"
            @click="selected = hr"
          >
            <div class="hr-item-l">
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                v-if="selected && selected.id === hr.id"
              >
                <path
                  d="M15.22 9.06222L10.93 13.3622L9.28 11.7122C9.19036 11.6075 9.08004 11.5225 8.95597 11.4625C8.83191 11.4025 8.69678 11.3687 8.55906 11.3634C8.42134 11.3581 8.28401 11.3813 8.15568 11.4316C8.02736 11.4819 7.91081 11.5581 7.81335 11.6556C7.7159 11.753 7.63964 11.8696 7.58937 11.9979C7.53909 12.1262 7.51589 12.2636 7.52121 12.4013C7.52653 12.539 7.56026 12.6741 7.62028 12.7982C7.6803 12.9223 7.76532 13.0326 7.87 13.1222L10.22 15.4822C10.3134 15.5749 10.4243 15.6482 10.5461 15.698C10.6679 15.7478 10.7984 15.773 10.93 15.7722C11.1923 15.7711 11.4437 15.667 11.63 15.4822L16.63 10.4822C16.7237 10.3893 16.7981 10.2787 16.8489 10.1568C16.8997 10.0349 16.9258 9.90423 16.9258 9.77222C16.9258 9.6402 16.8997 9.5095 16.8489 9.38764C16.7981 9.26578 16.7237 9.15518 16.63 9.06222C16.4426 8.87597 16.1892 8.77142 15.925 8.77142C15.6608 8.77142 15.4074 8.87597 15.22 9.06222ZM12.5 2.27222C10.5222 2.27222 8.58879 2.85871 6.9443 3.95752C5.29981 5.05633 4.01809 6.61812 3.26121 8.44538C2.50433 10.2726 2.3063 12.2833 2.69215 14.2231C3.078 16.1629 4.03041 17.9448 5.42894 19.3433C6.82746 20.7418 8.60929 21.6942 10.5491 22.0801C12.4889 22.4659 14.4996 22.2679 16.3268 21.511C18.1541 20.7541 19.7159 19.4724 20.8147 17.8279C21.9135 16.1834 22.5 14.25 22.5 12.2722C22.5 10.959 22.2413 9.65864 21.7388 8.44538C21.2363 7.23213 20.4997 6.12973 19.5711 5.20115C18.6425 4.27256 17.5401 3.53597 16.3268 3.03342C15.1136 2.53087 13.8132 2.27222 12.5 2.27222ZM12.5 20.2722C10.9178 20.2722 9.37104 19.803 8.05544 18.924C6.73985 18.0449 5.71447 16.7955 5.10897 15.3337C4.50347 13.8719 4.34504 12.2633 4.65372 10.7115C4.9624 9.15965 5.72433 7.73418 6.84315 6.61536C7.96197 5.49654 9.38743 4.73462 10.9393 4.42593C12.4911 4.11725 14.0997 4.27568 15.5615 4.88118C17.0233 5.48668 18.2727 6.51206 19.1518 7.82765C20.0308 9.14325 20.5 10.69 20.5 12.2722C20.5 14.3939 19.6572 16.4288 18.1569 17.9291C16.6566 19.4294 14.6217 20.2722 12.5 20.2722Z"
                  fill="#12B76A"
                />
              </svg>
            </div>
            <div class="hr-item-r">
              <p class="mb-0 fs-16 fw-700 txt-black max-line-1">
                {{ hr.visit_reason }}
              </p>
              <p class="mb-0 fs-14 fw-700 txt-grey-600 max-line-1">
                {{ fmDMY(hr.visited_at) }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div
            class="btn w-100 bg-pri text-white cursor-pointer"
            @click="createHealthRecord()"
          >
            Tạo Sổ khám bệnh
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <button
          class="btn bg-pri text-white cursor-pointer"
          :disabled="!selected"
          @click="onContinue()"
          type="button"
        >
          Tiếp tục
        </button>
      </div>
    </el-dialog>
    <ModalAddHR ref="ModalAddHR" :person="person" @refresh="refresh" />
  </div>
</template>

<script>
import ModalAddHR from '../../pages/HealthRecords/ModalAdd.vue'
import moment from 'moment'
export default {
  name: 'ModalSelectHR',
  props: ['person'],
  components: { ModalAddHR },
  data () {
    return {
      dialogVisible: false,
      healthRecords: [],
      selected: null
    }
  },
  mounted () {
    this.getHealthRecords()
  },
  methods: {
    refresh (selected) {
      this.selected = selected
      this.getHealthRecords()
    },
    async getHealthRecords () {
      try {
        const param = {
          limit: 1000,
          person_id: this.person?.id
        }
        await this.$rf.getRequest('DoctorRequest').getHealthRecords(param).then(res => {
          this.healthRecords = res?.data?.data
        })
      } catch (e) {
        console.log(e)
      }
    },
    show (stt) {
      this.dialogVisible = stt
    },
    fmDMY (d) {
      return d && moment(d).format('DD/MM/YYYY')
    },
    createHealthRecord (obj) {
      this.$refs.ModalAddHR.openDialog(obj)
    },
    onContinue () {
      this.$emit('onContinue', this.selected)
    }
  }
}
</script>

<style scoped>
.hr-item {
  width: 100%;
  height: 47px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
}
.hr-item:hover,
.hr-item.active {
  background-color: #e7eaf5;
}
.hr-item-l {
  width: 25px;
  align-content: center;
  justify-content: center;
  display: flex;
  margin-right: 8px;
}
.height-70vh {
  height: 60vh;
  overflow: auto;
}
.h-100p {
  height: 100%;
  overflow: auto;
}
</style>