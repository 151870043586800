<template>
  <div class="row mt-1">
    <div
      class="p-3"
      style="min-height: calc(100vh - 800px)"
      :class="
        selected_doctor && !selected_date
          ? 'col-xl-8 offet-xl-2 col-md-10 offset-md-1'
          : selected_doctor && selected_date
          ? 'col-12'
          : 'col-xl-5 offset-lg-3 col-md-6 offset-md-6'
      "
    >
      <div class="row modal-body-appt">
        <div
          :class="
            selected_doctor && !selected_date
              ? 'col-md-6'
              : selected_doctor && selected_date
              ? 'col-md-4'
              : 'col-12'
          "
        >
          <p class="mb-4 robo-24-500 txt-black">Thông tin lịch hẹn</p>
          <!-- Chọn bệnh nhân -->
          <div class="mb-3">
            <label class="form-label required d-flex align-items-center">
              <img
                src="../../../../public/assets/images/icon/select-patient.svg"
                width="24"
                alt
              />
              <span class="ml-1 txt-pri robo-16-500">Chọn bệnh nhân</span>
            </label>

            <v-select
              class="select-box"
              :options="(persons && persons.data) || []"
              :disabled="
                (!!hr && !!hr.person) || typeof personData !== 'undefined'
              "
              label="name"
              v-model="selected_person"
              placeholder="Chọn bệnh nhân"
              :filterable="false"
              @search="searchPerson"
              @input="selectedPerson()"
              @open="onOpenPerson"
              @close="onClosePerson"
            >
              <div slot="no-options">
                <span v-if="!loading_persons"
                  >Không tìm thấy bệnh nhân nào!</span
                >
              </div>
              <template v-slot:option="option">
                <span class="font-weight-bold">{{ option.name }}</span>
                - {{ option.phone }}
              </template>
              <template #list-footer>
                <li class="text-center mt-1">
                  <span
                    class="text-secondary spinner-border spinner-border-sm mr-2"
                    role="status"
                    v-if="loading_persons"
                  ></span>
                </li>
                <li ref="person_list" v-show="hasNextPagePerson"></li>
              </template>
            </v-select>
            <div class="text-danger font-size-12 ml-2">{{ err_patient }}</div>
          </div>
          <div
            class="radius-10 border border-primary p-2"
            v-if="selected_consulting"
          >
            <div class="flex">
              <div @click="backStep()" class="ml-auto cursor-pointer">
                <img
                  src="../../../../public/assets/images/icon/close-gray.svg"
                  alt
                />
              </div>
            </div>
            <div class="text-center">
              {{ selected_consulting.ConsultingRoom.Name }}
            </div>
            <div class="m-2">
              <div>
                Bác sĩ phụ trách: {{ selected_consulting.Doctor.User.name }}
              </div>
              <div>
                Địa chỉ: {{ selected_consulting.ConsultingRoom.Address }}
              </div>
              <div v-if="currentDate === moment().format('YYYY-MM-DD')">
                Hôm nay ({{ moment().format("DD/MM/YYYY") }}):
              </div>
              <div v-else>({{ currentDate }}):</div>
              <div class="ml-2">
                - Lượt khám còn lại: {{ selected_consulting.SlotRemain }}
              </div>
              <div class="ml-2">
                - Thời gian khám:
                {{ selected_consulting.ConsultingRoom.Slot_Duration }} phút
              </div>
            </div>
            <div class="flex justify-center items-end flex-1">
              <div
                :class="form.method == 1 ? 'bg-pri' : 'bg-f4'"
                class="radius-10 text-center p-2 h-10 cursor-pointer"
                @click="onHandleChangeMethod(1)"
              >
                <p
                  class="mb-0 robo-14-400 mt-1"
                  :class="form.method == 1 ? 'text-white' : ''"
                >
                  Đặt khám
                </p>
              </div>
              <div
                :class="form.method == 2 ? 'bg-pri' : 'bg-f4'"
                class="ml-2 radius-10 text-center p-2 cursor-pointer"
                @click="onHandleChangeMethod(2)"
              >
                <p
                  class="mb-0 robo-14-400 mt-1"
                  :class="form.method == 2 ? 'text-white' : ''"
                >
                  Tư vấn Online
                </p>
              </div>
            </div>
          </div>
          <!-- Chọn phòng tư vấn -->
          <div class="mb-3" v-if="false">
            <label class="form-label required d-flex align-items-center">
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.8994 17H11.8994V18C11.8994 18.2652 12.0048 18.5196 12.1923 18.7071C12.3798 18.8946 12.6342 19 12.8994 19C13.1646 19 13.419 18.8946 13.6065 18.7071C13.7941 18.5196 13.8994 18.2652 13.8994 18V17H14.8994C15.1646 17 15.419 16.8946 15.6065 16.7071C15.7941 16.5196 15.8994 16.2652 15.8994 16C15.8994 15.7348 15.7941 15.4804 15.6065 15.2929C15.419 15.1054 15.1646 15 14.8994 15H13.8994V14C13.8994 13.7348 13.7941 13.4804 13.6065 13.2929C13.419 13.1054 13.1646 13 12.8994 13C12.6342 13 12.3798 13.1054 12.1923 13.2929C12.0048 13.4804 11.8994 13.7348 11.8994 14V15H10.8994C10.6342 15 10.3798 15.1054 10.1923 15.2929C10.0048 15.4804 9.89941 15.7348 9.89941 16C9.89941 16.2652 10.0048 16.5196 10.1923 16.7071C10.3798 16.8946 10.6342 17 10.8994 17ZM19.8994 6H17.8994V5C17.8994 4.20435 17.5833 3.44129 17.0207 2.87868C16.4581 2.31607 15.6951 2 14.8994 2H10.8994C10.1038 2 9.3407 2.31607 8.77809 2.87868C8.21548 3.44129 7.89941 4.20435 7.89941 5V6H5.89941C5.10376 6 4.3407 6.31607 3.77809 6.87868C3.21548 7.44129 2.89941 8.20435 2.89941 9V19C2.89941 19.7956 3.21548 20.5587 3.77809 21.1213C4.3407 21.6839 5.10376 22 5.89941 22H19.8994C20.6951 22 21.4581 21.6839 22.0207 21.1213C22.5833 20.5587 22.8994 19.7956 22.8994 19V9C22.8994 8.20435 22.5833 7.44129 22.0207 6.87868C21.4581 6.31607 20.6951 6 19.8994 6ZM9.89941 5C9.89941 4.73478 10.0048 4.48043 10.1923 4.29289C10.3798 4.10536 10.6342 4 10.8994 4H14.8994C15.1646 4 15.419 4.10536 15.6065 4.29289C15.7941 4.48043 15.8994 4.73478 15.8994 5V6H9.89941V5ZM20.8994 19C20.8994 19.2652 20.7941 19.5196 20.6065 19.7071C20.419 19.8946 20.1646 20 19.8994 20H5.89941C5.6342 20 5.37984 19.8946 5.19231 19.7071C5.00477 19.5196 4.89941 19.2652 4.89941 19V12H20.8994V19ZM20.8994 10H4.89941V9C4.89941 8.73478 5.00477 8.48043 5.19231 8.29289C5.37984 8.10536 5.6342 8 5.89941 8H19.8994C20.1646 8 20.419 8.10536 20.6065 8.29289C20.7941 8.48043 20.8994 8.73478 20.8994 9V10Z"
                  fill="#20409B"
                />
              </svg>
              <span class="ml-1 txt-pri robo-16-500">Chọn Phòng Tư vấn</span>
            </label>
            <v-select
              class="select-box"
              :options="(consulting && consulting) || []"
              v-model="selected_consulting"
              label="name"
              placeholder="Chọn Phòng"
              @search="searchConsulting"
              :filterable="false"
              @input="selectedConsulting"
              :disabled="loading_time || loading_doctors"
            >
              <div slot="no-options">
                <span v-if="!loading_consulting"
                  >Không tìm thấy Phòng nào!</span
                >
              </div>
              <template #list-footer>
                <li class="text-center mt-1">
                  <span
                    class="text-secondary spinner-border spinner-border-sm mr-2"
                    role="status"
                    v-if="loading_doctors"
                  ></span>
                </li>
              </template>
            </v-select>
            <div class="text-danger font-size-12 ml-2">{{ err_doctor }}</div>
          </div>
          <!-- Chọn bác sĩ -->
          <div class="mb-3" v-if="false">
            <label class="form-label required d-flex align-items-center">
              <img
                src="../../../../public/assets/images/icon/select-doctor.svg"
                width="24"
                alt
              />
              <span class="ml-1 txt-pri robo-16-500">Chọn bác sĩ</span>
            </label>
            <v-select
              class="select-box"
              :options="(doctors && doctors.data) || []"
              v-model="selected_doctor"
              label="name"
              placeholder="Chọn bác sĩ"
              @search="searchDoctor"
              :filterable="false"
              @input="selectedDoctor"
              :disabled="loading_time"
            >
              <div slot="no-options">
                <span v-if="!loading_doctors && selected_consulting"
                  >Không tìm thấy bác sĩ nào!</span
                >
                <span v-if="!loading_doctors && !selected_consulting"
                  >Chọn phòng tư vấn trước!</span
                >
              </div>
              <template #list-footer>
                <li class="text-center mt-1">
                  <span
                    class="text-secondary spinner-border spinner-border-sm mr-2"
                    role="status"
                    v-if="loading_doctors"
                  ></span>
                </li>
              </template>
            </v-select>
            <div class="text-danger font-size-12 ml-2">{{ err_doctor }}</div>
          </div>
          <!-- Button confirm -->
          <div class="row mb-3" v-if="false">
            <div class="col-12 p-2">
              <div>
                <div
                  :class="form.method == 1 ? 'bg-pri' : 'bg-f4'"
                  class="radius-10 text-center p-3 cursor-pointer"
                  @click="onHandleSelectedMethod(1)"
                >
                  <img
                    src="../../../../public/assets/images/icon/hospital.svg"
                    v-show="form.method != 1"
                    alt
                  />
                  <img
                    src="../../../../public/assets/images/icon/white-hospital.svg"
                    v-show="form.method == 1"
                    alt
                  />
                  <p
                    class="mb-0 robo-14-400 mt-1"
                    :class="form.method == 1 ? 'text-white' : ''"
                  >
                    Tại Phòng Khám
                  </p>
                </div>
              </div>
            </div>
            <!-- <div class="col-6 p-2">
              <div>
                <div
                  :class="form.method == 2 ? 'bg-pri' : 'bg-f4'"
                  class="radius-10 text-center p-3 cursor-pointer"
                  @click="onHandleSelectedMethod(2)"
                >
                  <img
                    src="../../../../public/assets/images/icon/blue-video.svg"
                    v-show="form.method != 2"
                    alt
                  />
                  <img
                    src="../../../../public/assets/images/icon/white_video.svg"
                    v-show="form.method == 2"
                    alt
                  />
                  <p
                    class="mb-0 robo-14-400 mt-1"
                    :class="form.method == 2 ? 'text-white' : ''"
                  >Tư vấn Online</p>
                </div>
              </div>
            </div>-->
          </div>
          <!-- Tư vấn online -->
          <!-- <div class="mb-3">
            <div class="d-flex align-items-center" v-if="form.method == 2">
              <img src="../../../../public/assets/images/icon/clock-blue.svg" alt class="mr-1" />
              <span class="robo-16-500 txt-pri">Thời lượng {{ distance.time }} phút</span>
            </div>
          </div>-->
        </div>
        <!-- Chọn lượt -->
        <div
          :class="selected_date ? 'col-md-8' : 'col-md-6'"
          v-show="selected_doctor"
        >
          <div class="mb-3 row">
            <div :class="selected_date ? 'col-md-8' : 'col-12'">
              <p class="mb-4 ml-3 robo-24-500 txt-black">Chọn thời gian</p>
              <v-date-picker
                v-model="selected_date"
                @input="onChangeDate()"
                :attributes="attributes"
                :min-date="new Date()"
                is-expanded
                @update:from-page="pageChange"
              />
            </div>
            <div class="col-4" v-show="selected_date">
              <label class="ml-2 mt-4 mb-2 robo-18-500 txt-black">{{
                formatDMY(selected_date)
              }}</label>
              <div
                class="d-flex justify-content-center align-items-center py-5"
                v-if="loading_time"
              >
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="p-2 appt-time-slot">
                <div v-if="(!timeslots || !timeslots.length) && !loading_time">
                  <p class="text-center fs-16 fw-500">
                    Không có lịch trống nào
                  </p>
                </div>
                <div
                  v-for="(slot, index) in timeslots"
                  :key="index"
                  @click="selectedTime(slot)"
                >
                  <div class="group flex time-slot time-slot-mouseover -mx-1">
                    <div class="w-full px-1">
                      <div
                        class="h-100 d-flex justify-content-center align-items-center border-1 border-solid border-blue-800 text-blue-800 group-hover:bg-blue-50 transition-all duration-200"
                      >
                        <p class="robo-20-500 mb-0">{{ slot.Start }}</p>
                      </div>
                    </div>
                    <div
                      :class="`w-full px-1 ${
                        show_slot !== slot.Start
                          ? 'hidden group-hover:block'
                          : ''
                      } transition-all duration-200`"
                    >
                      <div
                        class="h-100 d-flex justify-content-center align-items-center bg-pri"
                        @click.stop="selectedTime(slot)"
                      >
                        <p class="robo-20-500 mb-0 text-white">Chọn</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-show="!selected_consulting">
      <template>
        <div v-for="cr in consulting" :key="cr.ID" class="col-3 p-2">
          <div class="radius-10 border room-item border-primary p-2">
            <div class="text-center">{{ cr.ConsultingRoom.Name }}</div>
            <div class="m-2">
              <div>Bác sĩ phụ trách: {{ cr.Doctor.User.name }}</div>
              <div>Địa chỉ: {{ cr.ConsultingRoom.Address }}</div>
              <div v-if="currentDate === moment().format('YYYY-MM-DD')">
                Hôm nay ({{ moment().format("DD/MM/YYYY") }}):
              </div>
              <div v-else>{{ currentDate }}</div>
              <div class="ml-2">- Lượt khám còn lại: {{ cr.SlotRemain }}</div>
              <div class="ml-2">
                - Thời gian khám: {{ cr.ConsultingRoom.Slot_Duration }} phút
              </div>
            </div>
            <div class="flex justify-center items-end flex-1">
              <div
                :class="form.method == 1 ? 'bg-pri' : 'bg-f4'"
                class="radius-10 text-center p-2 h-10 cursor-pointer"
                @click="onHandleSelectedMethod(1, cr)"
              >
                <p
                  class="mb-0 robo-14-400 mt-1"
                  :class="form.method == 1 ? 'text-white' : ''"
                >
                  Đặt khám
                </p>
              </div>
              <div
                :class="form.method == 2 ? 'bg-pri' : 'bg-f4'"
                class="ml-2 radius-10 text-center p-2 cursor-pointer"
                @click="onHandleSelectedMethod(2, cr)"
              >
                <p
                  class="mb-0 robo-14-400 mt-1"
                  :class="form.method == 2 ? 'text-white' : ''"
                >
                  Tư vấn Online
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex mt-2 justify-between items-center">
          <div class="fs-16 sm:mb-2 xl:mb-0 float-left">
            Hiển thị đến {{ countItems }} trên {{ totalItems }} mục
          </div>
          <el-pagination
            background
            layout="prev, pager, next"
            :pager-count="5"
            :page-size="DEFAULT_SIZE_OF_PAGE"
            :total="totalItems"
            class="el-pagination-mdt"
            @next-click="handleChangePage"
            @prev-click="handleChangePage"
            @current-change="handleChangePage"
          ></el-pagination>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import _, { debounce } from 'lodash'
import moment from 'moment'
import vSelect from 'vue-select'
import appUtils from '../../../utils/appUtils'
import {
  PATIENT_RECEPTION_TYPE,
  USE_ROOM_PRICE_CR
} from '../../../utils/constants'

export default {
  name: 'AppointmentForm',
  props: ['personData'],
  components: { vSelect },
  data () {
    const DEFAULT_SIZE_OF_PAGE = 12

    return {
      moment,
      doctors: null,
      persons: null,
      form: {
        start_time: '',
        end_time: '',
        note: '',
        appt_reason: '',
        person_id: '',
        doctor_id: null,
        method: PATIENT_RECEPTION_TYPE.CODE.APPOINTMENT,
        price: 0
      },
      err_patient: '',
      err_doctor: '',
      err_time: '',
      selected_doctor: null,
      selected_person: null,
      selected_consulting: null,
      loading_persons: false,
      loading_doctors: false,
      loading_consulting: false,
      isLoading: true,
      clinic: null,
      distance: {
        time: 15,
        price: 0
      },
      timeslots: [],
      choosed_time: null,
      choosed_date: null,
      selected_date: moment().locale('vi').toDate(),
      blocked_times: null,
      selected_time: null,
      show_slot: null,
      loading: false,
      search_person: '',
      consulting: null,
      doctor_kw: '',
      loading_time: false,
      hr: null,
      apptData: null,
      currentDate: moment().format('YYYY-MM-DD'),
      DEFAULT_SIZE_OF_PAGE,
      page: 1,
      totalItems: null,
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      attributes: [],
      dateHightLight: {
        highlight: 'green',
        dates: null
      }
    }
  },
  async created () {
    if (this.personData) {
      this.apptData = this.personData
      this.selected_person = this.apptData?.person || this.apptData
      this.selected_doctor = this.apptData?.doctor
      this.selected_consulting = this.apptData?.consulting_room
      this.form.method =
        this.apptData?.method || PATIENT_RECEPTION_TYPE.CODE.APPOINTMENT
      this.selected_date = this.moment(this.apptData?.start_time)
        .locale(this.$lang)
        .toDate()
      this.selected_time = this.apptData?.time
      this.show_slot = this.moment(this.selected_time?.start_time)
        .locale(this.$lang)
        .format('HH:mm')
    }
    await this.preLoading()
  },
  computed: {
    personList () {
      return this.persons?.data || []
    },
    countItems () {
      return this.page * this.DEFAULT_SIZE_OF_PAGE > this.totalItems
        ? this.totalItems
        : this.page * this.DEFAULT_SIZE_OF_PAGE
    }
  },
  watch: {
    selected_consulting: async function (consulting, old) {
      this.selected_doctor = null
      if (consulting) {
        this.err_consulting = null
        const price =
          consulting?.ConsultingRoom?.IsUsingRoomPrice ===
          USE_ROOM_PRICE_CR.isUse
            ? consulting?.ConsultingRoom.Slot_Price
            : consulting?.ServiceLocal?.local_price

        this.distance = {
          time: consulting?.ConsultingRoom.Slot_Duration,
          price
        }
        // this.selected_doctor = { id: consulting.DoctorID }

        await this.getDoctors()
        this.checkDoctorAvailableByMonth()
      } else {
      }
    },
    timeslots: function (timeslots, old) {
      let self = this
      self.selected_time = null
      self.show_slot = null
    },
    selected_doctor: function (doctor, old) {
      let self = this
      self.selected_time = null
      self.timeslots = []
      self.form.start_time = ''
      self.form.end_time = ''
      if (self.selected_doctor) {
        self.form.doctor_id = self.selected_doctor.id
        self.err_doctor = null
        if (self.selected_date) {
          self.onChangeDate()
        }
      } else {
        self.form.doctor_id = null
      }
      this.checkDoctorAvailableByMonth()
    },
    // personData (data) {
    //   if (data) {
    //     this.apptData = data
    //     this.selected_person = this.apptData?.person || this.apptData
    //     this.selected_doctor = this.apptData?.doctor
    //     this.selected_consulting = this.apptData?.consulting_room
    //     // this.form.method = this.apptData?.method || PATIENT_RECEPTION_TYPE.CODE.APPOINTMENT
    //     this.selected_date = this.moment(this.apptData?.start_time).locale(this.$lang).toDate()
    //     this.selected_time = this.apptData?.time
    //     this.show_slot = this.moment(this.selected_time?.start_time).locale(this.$lang).format('HH:mm')
    //   }
    // }
    page () {
      this.getConsultingRooms()
    }
  },

  async mounted () {
    await this.preLoading()
  },
  methods: {
    async preLoading () {
      this.getDoctors()
      if (!this.$route.query?.cr_id) {
        await this.getConsultingRooms()
      }
      if (!this.$route.query?.person_id) {
        await this.getDoctorPersons()
      }
      this.observerPerson = new IntersectionObserver(this.infiniteScrollPerson)
      this.clinic = this.$globalClinic
      if (this.$route.query.hr_id) {
        await this.getHealthRecordByID(this.$route.query.hr_id)
      }
      if (this.personData) {
        this.show_slot = this.moment(this.personData?.time?.start_time)
          .locale(this.$lang)
          .format('HH:mm')
      }
      if (this.$route.query?.cr_id || this.$route.query?.person_id) {
        await this.getConsultingRooms(1000)
        await this.getDoctorPersons(1000)
        this.selected_consulting = this.consulting?.find((elm) => {
          return elm?.ConsultingRoomID == this.$route.query.cr_id
        })
        this.selected_person = this.persons?.data?.find((elm) => {
          return elm?.id == this.$route.query.person_id
        })
      }
    },
    pageChange (e) {
      // const params = {
      //   month: e.month,
      // };
      this.month = e.month
      this.year = e.year
      this.checkDoctorAvailableByMonth()
    },

    backStep () {
      this.selected_doctor = null
      this.selected_consulting = null
      this.selected_date = moment().locale('vi').toDate()
      this.currentDate = moment().format('YYYY-MM-DD')
    },
    formatTime (item) {
      return window.moment(item).format('HH:mm')
    },
    async getHealthRecordByID (id) {
      try {
        if (id) {
          await this.$rf
            .getRequest('DoctorRequest')
            .getHealthRecordByID(id)
            .then((res) => {
              this.hr = res.data
              this.selected_person = this.hr.person
            })
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getConsultingRooms (limit) {
      this.loading_consulting = true
      const params = {
        limit: this.DEFAULT_SIZE_OF_PAGE,
        page: this.page,
        // clinic_id: this.$globalClinic?.id
        Date: this.currentDate,
        CurrentTime: moment().format('YYYY-MM-DD HH:mm')
      }
      if (limit) params.limit = limit
      // if (kw) {
      //   params.search = kw
      // }
      try {
        let resp = await this.$rf
          .getRequest('DoctorRequest')
          .getConsultingRoomWithDoctor(params, this.$globalClinic?.id)
        this.consulting = resp.data.data
        this.totalItems = resp.data?.meta?.total || 0
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        this.loading_consulting = false
      }
    },
    onChangeDate () {
      let self = this
      if (self.selected_doctor) {
        self.timeslots = []
        self.choosed_time = null
        if (self.selected_date) self.getTimeSlot()
        // clear giờ đã chọn trước đây
        self.selected_time = null
      } else {
        self.err_doctor = 'Chưa chọn bác sĩ'
      }
    },
    async getTimeSlot () {
      let self = this
      let params = {
        DoctorID: String(self.selected_consulting.Doctor.id),
        Date: moment(self.selected_date).format('YYYY-MM-DD'),
        CurrentTime: moment().format('YYYY-MM-DD HH:mm'),
        CrID: String(self.selected_consulting.ConsultingRoomID)
      }
      this.loading_time = true
      await self.$rf
        .getRequest('AuthRequest')
        .getTimeSlotByCrID(params)
        .then((resp) => {
          // console.log(resp)

          self.timeslots = resp.data
          self.selected_consulting.SlotRemain = resp.data?.length || 0
          self.currentDate = moment(self.selected_date).format('DD/MM/YYYY')
        })
        .finally(() => {
          self.loading_time = false
        })
    },

    // Person

    // Person

    hasNextPagePerson () {
      let self = this
      return self.persons && self.persons.total > self.persons.count
    },
    async onOpenPerson () {
      if (this.hasNextPagePerson) {
        await this.$nextTick()
        this.observerPerson.observe(this.$refs.person_list)
      }
    },
    onClosePerson () {
      this.observerPerson.disconnect()
    },
    async infiniteScrollPerson ([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        await this.$nextTick()
        ul.scrollTop = scrollTop
        if (!self.loading_persons) {
          this.getDoctorPersons((this.persons?.count || 0) + 20)
        }
      }
    },
    async getDoctorPersons (limit) {
      let self = this
      let clinic_id = this.$clinicId
      self.loading_persons = true
      let params = {
        limit: limit || 20
      }
      if (self.search_person) {
        params.search = self.search_person
      }
      try {
        let resp = await self.$rf
          .getRequest('DoctorRequest')
          .getPersonsOfWorkspace(clinic_id, params)
        self.persons = resp.data
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading_persons = false
      }
    },
    searchPerson (search) {
      this.err_patient = null
      this.search_person = search
      this.handleDebounce()
    },
    handleDebounce: debounce(function () {
      this.getDoctorPersons(20)
    }, 500),

    selectedPerson () {
      let self = this
      self.getDoctorPersons()
    },

    // Doctor

    async getDoctors (kw) {
      let self = this
      if (!this.selected_consulting) return
      this.loading_doctors = true
      let params = {
        limit: 20,
        cr_id: this.selected_consulting?.id,
        clinic_id: this.$globalClinic?.id
      }
      if (kw) {
        params.search = kw
      }
      try {
        let resp = await this.$rf
          .getRequest('DoctorRequest')
          .getAllDoctors(params)
        self.doctors = resp.data
        if (self.doctors.count) {
          if (!kw) {
            let user = appUtils.getLocalUser()
            const checkUser = _.find(self.doctors.data, [
              'id',
              user?.doctor?.id
            ])
            if (checkUser) {
              self.this_doctor_user = user
              self.selected_doctor = user.doctor
            } else {
              self.form.doctor_id = self.doctors.data[0]?.id
              self.selected_doctor = self.doctors.data[0]
            }
          }
        }
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading_doctors = false
      }
    },
    searchDoctor (search) {
      this.err_doctor = null
      this.getDoctors(search)
    },
    searchConsulting (search) {
      this.err_consulting = null
      this.getConsultingRooms(search)
    },
    async selectedDoctor () {},
    selectedConsulting () {
      let self = this
      self.getConsultingRooms()
    },
    onHandleSelectedMethod (value, cr) {
      this.selected_consulting = { ...cr }
      this.selected_doctor = cr.Doctor.id
      this.form.method = value
    },
    onHandleChangeMethod (value) {
      this.form.method = value
    },
    clickTime (slot) {
      this.show_slot = this.moment(slot?.Start)
        .locale(this.$lang)
        .format('HH:mm')
    },
    selectedTime (slot) {
      this.selected_time = slot
      this.submit()
    },
    async submit () {
      if (!this.validate()) return

      let params = {
        disease: null,
        person: this.selected_person,
        doctor: this.selected_doctor,
        consulting: this.selected_consulting,
        selectedDate: moment(this.selected_date).format('YYYY-MM-DD'),
        time: this.selected_time,
        distance: this.distance,
        clinic: this.clinic,
        form: this.form,
        hr: this.hr
      }

      this.$emit('nextStep', params)
    },
    validate () {
      let self = this
      if (!self.selected_person) {
        self.err_patient = 'Vui lòng chọn bệnh nhân'
        return false
      } else if (!self.selected_doctor) {
        self.err_doctor = 'Vui lòng chọn bác sĩ'
        return false
      } else if (!self.selected_time) {
        self.err_time = 'Vui lòng chọn thời gian'
        return false
      } else {
        return true
      }
    },
    formatDMY (date) {
      return window.moment(date).format('DD/MM/YYYY')
    },
    // checkingSelectedSlot (slot) {
    //   if (this.show_slot === this.moment(slot.start_time).format('HH:mm')) {
    //     return false
    //   }
    //   return true
    // },
    handleChangePage (newPage) {
      this.page = newPage
    },
    async checkDoctorAvailableByMonth () {
      this.loading_consulting = true
      if (
        !this.selected_consulting?.DoctorID ||
        !this.selected_consulting?.ConsultingRoomID
      ) {
        return
      }
      this.attributes = []

      const params = {
        DoctorID: this.selected_consulting.DoctorID,
        CrId: this.selected_consulting.ConsultingRoomID,
        Month: this.month,
        Year: this.year
      }

      try {
        let resp = await this.$rf
          .getRequest('DoctorRequest')
          .checkAvailableDoctorByMonthCr(params)
        resp.data.result.forEach((x) => {
          if (x.IsOn === 1 && x.DayInMonth >= moment().date()) {
            const indexDate = { ...this.dateHightLight }
            indexDate.dates = new Date(this.year, this.month - 1, x.DayInMonth)
            this.attributes.push(indexDate)
          }
        })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        this.loading_consulting = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .dateDecor {
    color: #45b26b;
  }

  .loading-item-box {
    height: 53px;
  }

  .bg-f4 {
    background-color: #f4f4f4;
  }

  .radius-10 {
    border-radius: 10px;
  }

  .vc-container {
    border: 0 !important;
  }

  .btn-close {
    background: url(../../../../public/assets/images/icon/close-gray.svg)
      no-repeat center center/1.75rem auto !important;
  }

  .appt-time-slot {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }

  .time-slot-df {
    border: solid 1px #20419b;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .time-slot {
    height: 48px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .time-slot-mouseover .time {
    background-color: #8a8a8a;
  }

  .select-box {
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #f4f4f4;
    border-radius: 5px;
  }

  .room-item {
    display: flex;
    flex-direction: column;
    min-height: 230px;
  }

  .el-pagination-mdt {
    padding: 0;

    & .number:hover {
      color: #20419b !important;
    }

    & .number.active {
      background-color: #20419b !important;
    }
  }
}
</style>
