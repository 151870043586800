<template>
  <div>
    <div class="modal fade" id="modal__appt">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{
                $t(
                  !!appt_edit
                    ? "obj_health_records.lbl_update_appt"
                    : "obj_health_records.lbl_add_work_schedule"
                )
              }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="onSubmitAppt">
              <div class="row mb-3">
                <div class="col-xl-6">
                  <div class="mb-3">
                    <label class="ml-2 form-label font-weight-bold required">
                      {{ $t("obj_health_records.lbl_service") }}
                      :</label
                    >
                    <select
                      :disabled="root_type || appt_edit"
                      v-model="form.method"
                      class="form-select"
                    >
                      <option :value="1">
                        {{
                          $t("workspace_setting.lbl_to_workspace")[$orgModel]
                        }}
                      </option>
                      <option :value="2">
                        {{
                          $t(
                            "obj_health_records.lbl_online_medical_examination"
                          )
                        }}
                      </option>
                    </select>
                  </div>
                  <div v-if="form.method == 2" class="mb-3">
                    <label class="ml-2 form-label font-weight-bold required"
                      >{{ `${$t("obj_health_records.lbl_unit_price")}` }}:
                      <span class="text-right">{{
                        numberFormat(form.price ? form.price : 0)
                      }}</span>
                      vnđ</label
                    >
                    <!-- <div v-if="!appt_edit" class="input-group">
                                            <input type="number" class="form-control" v-model="form.price" placeholder="Nhập chi phí khám" @input="err_price = ''" min="0">
                                            <span class="input-group-text">VNĐ</span>
                                        </div> -->
                    <div
                      class="input-group"
                      v-if="
                        (appt_edit &&
                          appt_edit.invoice &&
                          appt_edit.status === 1) ||
                        !appt_edit
                      "
                    >
                      <input
                        type="number"
                        class="form-control"
                        v-model="form.price"
                        :placeholder="
                          $t('obj_health_records.lbl_enter_exam_price')
                        "
                        @input="err_price = ''"
                        min="0"
                      />
                      <span class="input-group-text">VNĐ</span>
                    </div>
                    <div class="text-danger font-size-12 ml-2">
                      {{ err_price }}
                    </div>
                  </div>
                  <div class="mb-3" v-if="disease">
                    <label class="ml-2 form-label font-weight-bold required"
                      >{{ $t("obj_health_records.lbl_appt_with") }}:</label
                    >
                    <select v-model="form.person_id" class="form-select">
                      <option v-if="disease.person" :value="disease.person_id">
                        {{ disease.person.name }} ({{
                          $t("obj_health_records.lbl_human_patient")
                        }})
                      </option>
                      <option
                        v-for="ps in diseases_persons.data"
                        :value="ps.id"
                        :key="ps.id"
                        v-if="ps.id !== disease.person.id"
                      >
                        {{ ps.name }} ({{
                          $t("obj_health_records.lbl_relatives")
                        }})
                      </option>
                    </select>
                  </div>
                  <div v-if="!appt_edit && !disease" class="mb-3">
                    <div class="row" v-if="!person_id ? true : false">
                      <div class="col-sm-6">
                        <label
                          class="ml-2 form-label font-weight-bold required mt-1"
                          >{{ $t("obj_health_records.lbl_patient") }}:</label
                        >
                      </div>
                      <div
                        class="col-sm-6 text-right"
                        v-if="!person_id ? true : false"
                      >
                        <a
                          href="javascript:;"
                          class="btn bg-pri bd-pri text-white w-100 mb-1"
                          @click="onShowModalAddPerson"
                          ><span class="fa fa-plus mr-1"></span>
                          {{ $t("obj_health_records.lbl_add_patient") }}
                        </a>
                      </div>
                    </div>
                    <v-select
                      v-if="!person_id ? true : false"
                      :disabled="person_id ? true : false"
                      :options="persons.data"
                      label="name"
                      v-model="selected_person"
                      :placeholder="$t('obj_health_records.lbl_select_patient')"
                      :filterable="false"
                      @search="searchPerson"
                      @input="selectedPerson()"
                    >
                      <div slot="no-options">
                        {{ $t("obj_health_records.lbl_no_patient_found") }}
                      </div>
                      <template v-slot:option="option">
                        <span class="font-weight-bold">{{ option.name }}</span>
                        - {{ option.phone }}
                      </template>
                      <template #list-footer>
                        <li class="text-center mt-1"></li>
                      </template>
                    </v-select>
                    <div class="text-danger font-size-12 ml-2">
                      {{ err_patient }}
                    </div>
                  </div>
                  <div v-if="isClinic" class="mb-3">
                    <label class="ml-2 form-label font-weight-bold required"
                      >{{ $t("title.attending_doctor") }}:</label
                    >
                    <v-select
                      v-if="doctors"
                      :options="doctors.data"
                      v-model="selected_doctor"
                      label="name"
                      :placeholder="$t('obj_health_records.lbl_select_doctor')"
                      @search="searchDoctor"
                      @input="selectedDoctor"
                    >
                      <div slot="no-options">
                        {{ $t("obj_health_records.lbl_no_doctor_found") }}
                      </div>
                    </v-select>
                    <div class="text-danger font-size-12 ml-2">
                      {{ err_doctor }}
                    </div>
                  </div>
                  <!-- <div v-if="isDoctor" class="mb-3">
                                        <label class="ml-2 form-label required">{{ $t("title.attending_doctor") }}:</label>
                                        <input type="text" disabled readonly :value="this_doctor_user.name" class="form-control" />
                                    </div> -->
                  <div class="mb-3">
                    <label class="ml-2 form-label font-weight-bold"
                      >{{ $t("obj_health_records.lbl_note") }}:</label
                    >
                    <textarea
                      v-model="form.note"
                      class="form-control"
                    ></textarea>
                  </div>
                </div>
                <div class="col-xl-6">
                  <label class="ml-2 form-label font-weight-bold required"
                    >{{ $t("title.time") }}:</label
                  >
                  <div class="text-danger font-size-12 ml-2">
                    {{
                      !selected_doctor
                        ? $t("obj_health_records.lbl_select_doctor_first")
                        : err_time
                    }}
                  </div>
                  <div :class="{ 'd-none': !selected_doctor }" class="mb-3">
                    <div id="start__date" class="box-select-date"></div>
                  </div>
                </div>
                <div v-if="selected_doctor" class="col-12">
                  <div v-if="choosed_date && times">
                    <div class="row">
                      <div class="col-sm-6 mb-2">
                        <label class="robo-14-500 text--gray">
                          {{ $t("obj_health_records.lbl_examination_time") }}
                        </label>
                      </div>
                      <div class="col-sm-6 mb-2">
                        <div
                          class="robo-14-500 text--gray d-sm-flex justify-content-end align-items-center"
                          v-if="choosed_time"
                        >
                          <p class="m-0 mr-2">
                            {{ $t("obj_health_records.lbl_selected_time") }}:
                          </p>
                          <p class="m-0">
                            <span class="robo-16-500 txt-pri"
                              >{{ choosed_time.hour }}:{{
                                choosed_time.minute
                              }}</span
                            >
                            -
                            <span class="robo-16-500 txt-pri">{{
                              getEndtime(choosed_time.hour, choosed_time.minute)
                            }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div v-if="times.length" class="mt-2 time-box-slot">
                      <div v-if="parseInt(time.hour) > 6">
                        <a
                          v-for="(time, index) in times"
                          :key="index"
                          href="javascript:;"
                          :class="getClassTimeSlot(time)"
                          class="btn time-slot"
                          @click="chooseTime(time)"
                        >
                          {{
                            parseInt(time.hour) < 10
                              ? time.hour.substring(1)
                              : time.hour
                          }}:{{ time.minute }} -
                          {{ getEndtime(time.hour, time.minute) }}
                        </a>
                      </div>
                      <div v-if="parseInt(time.hour) < 6">
                        <a
                          v-for="(time, index) in times"
                          href="javascript:;"
                          :class="getClassTimeSlot(time)"
                          class="btn time-slot"
                          @click="chooseTime(time)"
                          :key="index"
                        >
                          {{
                            parseInt(time.hour) < 10
                              ? time.hour.substring(1)
                              : time.hour
                          }}:{{ time.minute }} -
                          {{ getEndtime(time.hour, time.minute) }}
                        </a>
                      </div>

                      <!-- <div v-for="time in times">{{times}}</div> -->
                    </div>
                    <div v-if="!times.length">
                      <span class="text-secondary" v-if="!loading">{{
                        $t("obj_health_records.lbl_no_time")
                      }}</span>
                      <span
                        class="text-secondary spinner-border spinner-border-sm mr-2"
                        role="status"
                        v-if="loading"
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center mt-3">
                <button
                  type="submit"
                  class="btn-l bg-pri bd-pri text-white mr-3"
                  :disabled="loading"
                >
                  <span
                    class="spinner-border spinner-border-sm mr-2"
                    role="status"
                    v-if="loading"
                  ></span>
                  <span>{{ $t("obj_health_records.lbl_create_appt") }}</span>
                </button>
                <button
                  type="button"
                  class="btn-l btn--ghost"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  {{ $t("obj_health_records.lbl_cancel") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- modal add person -->
    <ModalAddPerson
      @onSubmit="onAddNewPerson"
      :birth="add_birth"
      :form="add_person"
      :isEdit="false"
      :loading="loading"
    ></ModalAddPerson>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import appUtils from '../../utils/appUtils'
import { mixinSelectPerson } from '../../utils/mixinSelectPerson'
import ModalAddPerson from '../Person/ModalAddPerson'
export default {
  name: 'ModalAppointment',
  mixins: [mixinSelectPerson],
  components: { vSelect, ModalAddPerson },
  props: [
    'root_screen',
    'screen',
    'appt_edit',
    'root_type',
    'request_id',
    'person_id',
    'appt_doctor_id',
    'disease'
  ],
  computed: {
    isClinic () {
      return appUtils.isClinic()
    },
    isDoctor () {
      return appUtils.isDoctor()
    },
    className () {
      if (this.isClinic) {
        return 'ClinicRequest'
      } else {
        return 'DoctorRequest'
      }
    }
  },
  data () {
    return {
      symptom: '',
      err_symptom: '',
      err_price: '',
      is_person_listing: false,
      is_appointment_add: true,
      start__time: '',
      times: [],
      open_time: '00:00',
      close_time: '23:59',
      block_time: [],
      selected_work_time: [],
      selected_person_name: '',
      err_doctor: '',
      err_patient: '',
      err_time: '',
      choosed_date: '',
      picked_time: '',
      choosed_time: null,
      loading: false,
      this_doctor_user: '',
      doctors: '',
      start_time: null,
      end_time: null,
      persons: [],
      selected_person: null,
      selected_doctor: '',
      form: {
        start_time: '',
        end_time: '',
        note: '',
        person_id: '',
        doctor_id: null,
        method: 1,
        price: 0
      },
      options: [
        {
          text: 'Online',
          value: 1,
          color: 'success'
        },
        {
          text: 'Offline',
          value: 2,
          color: 'danger'
        }
      ],
      add_birth: {
        day: '',
        month: '',
        year: ''
      },
      add_person: {
        name: '',
        phone: '',
        gender: '',
        cmnd: '',
        address: '',
        symptom: ''
      },
      user: {},
      clinic: {},
      invoice_id: 0,
      diseases_persons: []
    }
  },
  mounted () {
    let self = this
    let user = appUtils.getLocalUser()
    self.user = appUtils.getLocalUser()
    self.clinic = this.$globalClinic
    if (user && user.clinic) {
      self.open_time = user.clinic.open_time ? user.clinic.open_time : '00:00'
      self.close_time = user.clinic.close_time
        ? user.clinic.close_time
        : '23:59'
    }
    self.date_picker = window.flatpickr(
      document.getElementById('start__date'),
      {
        inline: true,
        dateFormat: 'Y-m-d',
        defaultDate: 'today',
        minDate: 'today',
        onChange: function (dates) {
          if (self.selected_doctor) {
            self.times = []
            self.choosed_time = ''
            self.choosed_date = self.newDate(dates[0])
            self.getTimeSlotBydoctor(self.selected_doctor.id)
            // clear giờ đã chọn trước đây
            self.start_time = ''
            self.end_time = ''
          } else {
            self.err_doctor = this.$t(
              'obj_health_records.lbl_no_select_doctor'
            )
          }
        }
      }
    )
    if (self.isDoctor) {
      self.getThisDoctor()
    }
    if (self.isClinic) {
      self.getDoctors()
    }
    window.$(document).ready(function () {
      window.$('#modal__add_person').on('hidden.bs.modal', function () {
        if (window.$('.modal:visible').length) {
          // check whether parent modal is opend after child modal close
          window.$('body').addClass('modal-open') // if open mean length is 1 then add a bootstrap css class to body of the page
        }
      })
      window.$('#modal__appt').on('shown.bs.modal', function (e) {
        if (self.disease) {
          self.getDiseasesPerson()
        }
        if (self.appt_edit) {
          self.getApptEdit()
        } else {
          setTimeout(() => {
            self.date_picker.setDate(self.newDate())
            self.choosed_date = self.newDate()
            self.getTimeSlotBydoctor(self.selected_doctor.id)
          }, 300)
          self.choosed_time = null
          self.form = {
            id: null,
            start_time: '',
            end_time: '',
            note: '',
            person_id: self.disease ? self.disease.person_id : '',
            doctor_id: null,
            method: self.root_type ? self.root_type : 1,
            price: 0
          }
          self.form.price =
            self.clinic && self.clinic.distance_price
              ? self.clinic.distance_price
              : 0
        }
        if (!self.disease && !self.appt_edit) {
          if (self.isClinic) {
            self.getPersons(null, self.person_id ? self.person_id : null)
          }
          if (self.isDoctor) {
            self.getDoctorPersons(null, self.person_id ? self.person_id : null)
          }
        }
      })
    })
  },
  methods: {
    async getDiseasesPerson () {
      let self = this
      if (self.disease && self.disease.created_by) {
        let params = {
          // not_parent_id: 1
        }
        await self.$rf
          .getRequest('DoctorRequest')
          .getPersonByUserId(
            self.disease.created_by.id || self.disease.created_by,
            params
          )
          .then((resp) => {
            if (resp && resp.data && resp.data.count) {
              self.diseases_persons = resp.data
            }
          })
      }
    },
    getApptEdit () {
      let self = this
      if (self.appt_edit) {
        self.form.id = self.appt_edit.id
        self.form.start_time = self.appt_edit.start_time
        self.form.note = self.appt_edit.note
        self.form.end_time = self.appt_edit.end_time
        self.form.person_id = self.appt_edit.person_id
        self.form.doctor_id = self.appt_edit.doctor_id
        self.form.method = self.appt_edit.method
        self.form.price = self.appt_edit.invoice
          ? self.appt_edit.invoice.price
          : 0
        self.date_picker.setDate(self.getDate(self.appt_edit.start_time))
        self.invoice_id = self.appt_edit.invoice
          ? self.appt_edit.invoice.id
          : 0
        self.choosed_time = {
          hour: self.getHours(self.appt_edit.start_time),
          minute: self.getMinutes(self.appt_edit.start_time)
        }
        self.choosed_date = self.newDate(self.appt_edit.start_time)
        setTimeout(() => {
          self.getTimeSlotBydoctor(self.selected_doctor.id)
        }, 300)
      }
    },
    getClassTimeSlot (time) {
      let self = this
      if (time.is_blocked) {
        return 'btn--ghost disabled'
      } else {
        return self.choosed_time === time
          ? 'bg-pri bd-pri text-white'
          : 'btn--ghost'
      }
    },
    async getDoctors (kw) {
      let self = this
      let params = {
        limit: 50
      }
      if (kw) {
        params.search = kw
      }
      try {
        let resp = await self.$rf
          .getRequest('ClinicRequest')
          .getDoctors(params)
        self.doctors = resp.data
        if (self.doctors.count) {
          if (!kw) {
            self.form.doctor_id = self.doctors.data[0].id
            self.selected_doctor = self.doctors.data[0]
          }
          self.doctors.data.forEach((doctor) => {
            Object.assign(doctor, { name: doctor.user.name })
          })
        }
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    getThisDoctor () {
      let user = appUtils.getLocalUser()
      if (user.doctor) {
        this.this_doctor_user = user
        this.selected_doctor = user.doctor
      }
    },
    searchDoctor (search) {
      this.err_doctor = null
      this.getDoctors(search)
    },
    async selectedDoctor () {
      let self = this
      self.err_doctor = null
      self.choosed_time = null
      self.picked_time = null
      self.times = []
      self.form.start_time = ''
      self.form.end_time = ''
      self.start_time = ''
      self.end_time = ''
      if (self.selected_doctor) {
        self.form.doctor_id = self.selected_doctor.id
        if (self.choosed_date) {
          self.getTimeSlotBydoctor(self.selected_doctor.id)
        }
      } else {
        self.form.doctor_id = null
      }
    },
    async onSubmitAppt () {
      let self = this
      if (!self.can('create-new-appt')) return
      let params = self.form
      if (params.method === 1) {
        delete params.price
      } else {
        if (isNaN(params.price) || params.price < 0) {
          self.err_price = this.$t('obj_health_records.lbl_verify_price')
          return false
        }
      }

      if (self.isDoctor) {
        if (self.appt_doctor_id) {
          params.doctor_id = self.appt_doctor_id
        } else {
          params.doctor_id = self.selected_doctor
            ? self.selected_doctor.id
            : ''
        }
        params.clinic_id = this.$clinicId
      }
      if (self.person_id) {
        params.person_id = self.person_id
      } else if (!params.person_id) {
        self.err_patient = this.$t('obj_health_records.lbl_no_select_patient')
        return false
      }
      if (!params.doctor_id) {
        self.err_doctor = this.$t('obj_health_records.lbl_no_select_doctor')
        self.loading = false
        return false
      }
      if (!self.start_time || !self.end_time) {
        if (self.appt_edit) {
          params.start_time = self.formatTime(self.appt_edit.start_time)
          params.end_time = self.formatTime(self.appt_edit.end_time)
        } else {
          self.err_time = this.$t('obj_health_records.lbl_no_select_time')
          return false
        }
      } else {
        params.start_time = self.formatTime(self.start_time)
        params.end_time = self.formatTime(self.end_time)
      }
      if (self.request_id) {
        params.request_id = self.request_id
      }
      if (self.disease) {
        params.person_diseases_id = self.disease.id
      }
      if (self.selected_person) {
        self.sendAmplitude('Select Patient', {
          'doctor id':
            self.user && self.user.doctor && self.user.doctor.id
              ? self.user.doctor.id
              : '',
          'doctor name': self.user && self.user.name ? self.user.name : '',
          'workspace id': self.clinic && self.clinic.id ? self.clinic.id : '',
          'workspace name':
            self.clinic && self.clinic.name ? self.clinic.name : '',
          timestamp: window.moment().format('HH:mm DD-MM-YYYY'),
          'request id': self.request_id ? self.request_id : '',
          'patient id':
            self.selected_person && self.selected_person.id
              ? self.selected_person.id
              : '',
          'patient name':
            self.selected_person && self.selected_person.name
              ? self.selected_person.name
              : ''
        })
      }
      if (self.form && self.form.note) {
        self.sendAmplitude('Add Note', {
          'doctor id':
            self.user && self.user.doctor && self.user.doctor.id
              ? self.user.doctor.id
              : '',
          'doctor name': self.user && self.user.name ? self.user.name : '',
          'workspace id': self.clinic && self.clinic.id ? self.clinic.id : '',
          'workspace name':
            self.clinic && self.clinic.name ? self.clinic.name : '',
          timestamp: window.moment().format('HH:mm DD-MM-YYYY'),
          'request id': self.request_id ? self.request_id : ''
        })
      }
      self.loading = true
      try {
        if (self.appt_edit) {
          await self.$rf
            .getRequest(self.className)
            .updateAppointment(params.id, params)
          if (params.price) {
            var param = {
              price: params.price
            }
            await self.$rf
              .getRequest(self.className)
              .postDoctorInvoices(self.invoice_id, param)
          }
          this.$toast.open({
            message: 'Cập nhật thành công',
            type: 'success'
          })
          self.sendAmplitude(
            self.appt_edit.method === 2 ? 'Edit Telemed' : 'Edit Appointment',
            {
              'doctor id':
                self.user && self.user.doctor && self.user.doctor.id
                  ? self.user.doctor.id
                  : '',
              'doctor name': self.user && self.user.name ? self.user.name : '',
              'workspace id':
                self.clinic && self.clinic.id ? self.clinic.id : '',
              'workspace name':
                self.clinic && self.clinic.name ? self.clinic.name : '',
              timestamp: window.moment().format('HH:mm DD-MM-YYYY'),
              'request id': self.request_id ? self.request_id : '',
              'patient id': params && params.person_id ? params.person_id : '',
              // 'patient name': self.selected_person ? self.selected_person.name : '',
              'new time value':
                params && params.start_time && params.end_time
                  ? params.start_time + ' - ' + params.end_time
                  : ''
            }
          )
        } else {
          let resp = await self.$rf
            .getRequest(self.className)
            .addAppointment(params)
          self.sendAmplitude(
            resp.data.method === 2 ? 'Save Telemed' : 'Save Appointment',
            {
              'doctor id':
                self.user && self.user.doctor && self.user.doctor.id
                  ? self.user.doctor.id
                  : '',
              'doctor name': self.user && self.user.name ? self.user.name : '',
              'workspace id':
                self.clinic && self.clinic.id ? self.clinic.id : '',
              'workspace name':
                self.clinic && self.clinic.name ? self.clinic.name : '',
              timestamp: window.moment().format('HH:mm DD-MM-YYYY'),
              'request id': self.request_id ? self.request_id : '',
              'patient id':
                self.selected_person && self.selected_person.id
                  ? self.selected_person.id
                  : '',
              'patient name':
                self.selected_person && self.selected_person.name
                  ? self.selected_person.name
                  : '',
              'time value':
                params && params.start_time && params.end_time
                  ? params.start_time + ' - ' + params.end_time
                  : ''
            }
          )
          if (resp.data.method === 2) {
            await self.createRoom(resp.data)
          } else {
            self.$toast.open({
              message: 'Tạo thành công',
              type: 'success'
            })
          }
        }
        self.$emit('onRefresh')
      } catch (e) {
        if (self.appt_edit) {
          self.sendAmplitude(
            self.appt_edit.method === 2 ? 'Edit Telemed' : 'Edit Appointment',
            {
              Status: 'Fail',
              Type: 'Modal',
              'Modal Name': 'Update Appointment',
              Screen: self.screen,
              'Root Screen': self.root_screen,
              Timestamp: self.formatDateTime(),
              Error: e
            }
          )
        } else {
          self.sendAmplitude(
            params.method === 2 ? 'Save Telemed' : 'Save Appointment',
            {
              Status: 'Fail',
              Type: 'Modal',
              'Modal Name': 'Add Appointment',
              Screen: self.screen,
              'Root Screen': self.root_screen,
              Timestamp: self.formatDateTime(),
              Error: e
            }
          )
        }
        // console.log(e)
      } finally {
        self.loading = false
        self.today_pending = 0
        self.today_arrived = 0
        self.blankFormAppt()
        self.appts = []
      }
    },
    async createRoom (appt) {
      let self = this
      let params = {
        appt_id: appt && appt.id ? appt.id : '',
        status: 1,
        clinic_id: this.$clinicId,
        person_id: appt && appt.person_id ? appt.person_id : '',
        receiver_phone: appt && appt.person ? appt.person.phone : '',
        name: appt && appt.person ? appt.person.name : '',
        doctor_id: appt && appt.doctor_id ? appt.doctor_id : ''
      }
      try {
        await self.$rf.getRequest('DoctorRequest').createRoom(params)
        self.$emit('onRefresh')
        self.$toast.open({
          message: this.$t('obj_health_records.lbl_create_success'),
          type: 'success'
        })
      } catch (e) {
        self.$toast.open({
          message: this.$t(
            'obj_health_records.lbl_error_when_create_telemedicine'
          ),
          type: 'warning'
        })
        console.log(e)
      }
    },

    blankFormAppt () {
      let self = this
      self.times = []
      self.selected_person_name = ''
      self.start_time = ''
      self.end_time = ''
      self.choosed_time = ''
      self.form = {
        id: null,
        start_time: '',
        end_time: '',
        note: '',
        person_id: '',
        method: 2,
        price: 0
      }
      self.selected_person = null
      self.selectedDoctor()
    },
    formatTime (date_time) {
      return window.moment(date_time).format('HH:mm DD/MM/YYYY')
    },
    formatDateTime (date_time) {
      return window.moment(date_time).format('HH:mm DD-MM-YYYY')
    },
    formatDate (date) {
      return window.moment(date).format('DD-MM-YYYY')
    },
    getEndtime (hour, minute) {
      let date = this.newDate()
      date.setHours(hour, minute)
      var distance_time =
        this.clinic && this.clinic.distance_time
          ? this.clinic.distance_time
          : ''
      return window
        .moment(date)
        .add(distance_time ? this.changeMinutes(distance_time) : 15, 'm')
        .format('H:mm')
      // return window
      //   .moment(date)
      //   .add(15, 'm')
      //   .format('H:mm')
    },
    changeMinutes (time) {
      // console.log(time)
      var arr = time.match(/^(\d{2}):(\d{2}):(\d{2})$/)
      // console.log(arr)
      return parseInt(arr[1]) * 60 + parseInt(arr[2])
    },
    async getTimeSlotBydoctor (doctor_id) {
      let self = this
      // reset dữ liệu cũ
      self.loading = true
      self.times = []
      self.block_time = []
      // khởi tạo data
      let start_time = self.choosed_date
      start_time.setHours(0, 0, 0, 0)
      let end_time = self.newDate(self.choosed_date)
      end_time.setHours(23, 59, 59, 59)
      let params = {
        limit: 100,
        start_time: self.formatDateTime(start_time),
        end_time: self.formatDateTime(end_time),
        doctor_id: doctor_id,
        states: [0, 1],
        statuses: [1, 2, 4, 5]
      }
      // lấy ra lịch hẹn theo ngày và bác sĩ đã chọn
      let resp = await self.$rf
        .getRequest(self.className)
        .getAppointments(params)
      resp.data.data.forEach((appt) => {
        // thêm vào object các thời gian bận
        self.block_time.push({
          start: window.moment(appt.start_time),
          end: window.moment(appt.end_time)
        })
      })
      self.times = []
      // Lấy ra thời gian làm việc ngày đã chọn
      self.getWorkTime()
    },
    async getWorkTime () {
      let self = this
      let params = {}
      if (self.isDoctor) {
        params.clinic_id = this.$clinicId
      }
      self.selected_work_time = []
      if (self.choosed_date) {
        // lấy danh sách thời gian hoạt động
        let class_name = self.isClinic
          ? 'ClinicRequest'
          : self.isDoctor
            ? 'DoctorRequest'
            : ''
        let resp = await self.$rf.getRequest(class_name).getWorkTime(params)
        // gán
        self.current_work_time = resp.data
        // lấy thứ tự của ngày đã chọn
        // console.log(self.current_work_time)
        let sl_date = self.newDate(self.choosed_date).getDay()
        // console.log(sl_date)
        if (self.current_work_time.length) {
          // vòng lặp lấy ra đúng ngày đã chọn
          self.current_work_time.forEach((day) => {
            if (sl_date === day.day) {
              // thêm vào object
              self.selected_work_time.push(day)
            }
          })
          if (self.selected_work_time) {
            // sắp xếp thứ tự lớn dần theo thời gian
            self.selected_work_time.sort((a, b) =>
              a.start_time < b.start_time
                ? -1
                : a.start_time > b.start_time
                  ? 1
                  : 0
            )
          }
        } else {
          self.selected_work_time = [
            { start_time: '00:00:00', end_time: '23:59:00' }
          ]
        }
      }
      self.getTimeStops()
    },
    getTimeStops () {
      let self = this
      self.loading = true
      if (self.selected_work_time) {
        // vòng lặp các thời gian hoạt động đã lấy ra
        // console.log(self.selected_work_time)
        self.selected_work_time.forEach((session) => {
          // gán start và end
          let start_time = self.getTime(session.start_time)
          let end_time = self.getTime(session.end_time)
          // console.log(session.start_time + '   ' + session.end_time)
          // console.log(start_time + '   ' + end_time)
          // vòng lặp lấy ra các slot từ start đến end
          let slot = 0
          var distance_time =
            self.clinic && self.clinic.distance_time
              ? self.changeMinutes(self.clinic.distance_time)
              : 15
          var split_time = distance_time * 60000
          // slot = (end_time.valueOf() - start_time.valueOf()) / 900000
          slot = (end_time.valueOf() - start_time.valueOf()) / split_time
          for (var i = 1; i <= slot; i++) {
            // gán start và end của slot
            let start_slot = start_time
            let end_slot = window.moment(start_time)
            end_slot.add(distance_time, 'minutes')
            // end_slot.add(15, 'minutes')
            if (start_slot.valueOf() > self.newDate().valueOf()) {
              // gọi đến function để check các thời gian đã bị block
              self.times.push({
                id: i,
                hour: start_time.format('HH'),
                minute: start_time.format('mm'),
                is_blocked: self.blockedTime(start_slot, end_slot)
              })
            }
            // kết thúc mỗi vòng lắp tăng 15 phút thời gian bắt đầu (start time)
            start_time.add(distance_time, 'minutes')
            // start_time.add(15, 'minutes')
          }
        })
        // console.log(self.times)
        self.loading = false
      }
    },
    chooseTime (time) {
      let self = this
      self.err_time = ''
      self.choosed_time = {}
      if (time) {
        self.choosed_time = time
        self.start_time = self.newDate(self.choosed_date)
        self.start_time.setHours(
          self.choosed_time.hour,
          self.choosed_time.minute
        )
        var distance_time =
          self.clinic && self.clinic.distance_time
            ? self.changeMinutes(self.clinic.distance_time)
            : 15
        // self.end_time = window
        //   .moment(self.start_time)
        //   .add(15, 'm')
        //   .toDate()
        self.end_time = window
          .moment(self.start_time)
          .add(distance_time, 'm')
          .toDate()
        this.sendAmplitude('Pick Time Slot', {
          'doctor id':
            self.user && self.user.doctor && self.user.doctor.id
              ? self.user.doctor.id
              : '',
          'doctor name': self.user && self.user.name ? self.user.name : '',
          'workspace id': self.clinic && self.clinic.id ? self.clinic.id : '',
          'workspace name':
            self.clinic && self.clinic.name ? self.clinic.name : '',
          timestamp: self.formatDateTime() ? self.formatDateTime() : '',
          'request id': self.request_id ? self.request_id : '',
          'time value':
            self.start_time && self.end_time
              ? self.formatDateTime(self.start_time) +
                '-' +
                self.formatDateTime(self.end_time)
              : ''
        })
      }
    },
    getTime (time) {
      let new_date = window.moment(this.choosed_date).format('YYYY-MM-DD')
      return window.moment(new_date + ' ' + time)
    },
    getDate (date_time) {
      return window.moment(date_time).format('YYYY-MM-DD')
    },
    getHours (date_time) {
      return window.moment(date_time).format('HH')
    },
    getMinutes (date_time) {
      return window.moment(date_time).format('mm')
    },
    blockedTime (start_time, end_time) {
      if (this.block_time) {
        // so sánh thời gian bắt đầu với thời gian đã bị block
        return this.block_time.some((br) => {
          return (
            start_time >= br.start &&
            start_time < br.end &&
            end_time >= br.start &&
            end_time <= br.end
          )
        })
      }
    },
    numberFormat (number) {
      return parseInt(number).toLocaleString(undefined, {
        minimumFractionDigits: 0
      })
    },
    onShowModalAddPerson (show) {
      window.$('#modal__add_person').modal(show ? 'show' : 'hide')
      // window.$('#modal__appt').modal(!show ? 'show' : 'hide')
    },
    newDate (date_time) {
      return window.moment(date_time).toDate()
    }
  }
}
</script>
<style scoped>
.choosed-time {
  cursor: pointer;
  background-position: right !important;
  background-repeat: no-repeat !important;
  background-image: url(../../../public/assets/images/icon/tick.png) !important;
}

.time-box-slot {
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 133px;
}

.time-slot {
  width: 140px;
  margin: 5px;
}
.font-size-12 {
  font-size: 12px;
}

#modal__appt .form-select,
#modal__appt .form-control,
#modal__appt .vs__dropdown-toggle {
  background-color: #ebf1ff !important;
  border-radius: 8px;
  border: none;
  height: 61px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #20419b;
}

#modal__appt .vs__selected {
  color: #20419b;
}

#modal__appt .input-group .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

#modal__appt .input-group .input-group-text,
#modal__appt .vs__search {
  background-color: #ebf1ff;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #b6c2cf;
}
</style>