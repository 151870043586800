<template>
  <div class="row mt-1">
    <div
      class="p-3 border border-bottom-0 bg-white"
      style="min-height: calc(100vh - 147px)"
      :class="
        selected_consulting && !selected_date
          ? 'col-xl-8 offet-xl-2 col-md-10 offset-md-1'
          : selected_consulting && selected_date
          ? 'col-12'
          : 'col-xl-5 offset-lg-3 col-md-6 offset-md-6'
      "
    >
      <div class="row modal-body-appt">
        <div
          :class="
            selected_consulting && !selected_date
              ? 'col-md-6'
              : selected_consulting && selected_date
              ? 'col-md-4'
              : 'col-12'
          "
        >
          <p class="mb-4 robo-24-500 txt-black">
            {{ $t("obj_health_records.lbl_appt_info") }}
          </p>
          <div class="mb-3">
            <label class="form-label required d-flex align-items-center">
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.8994 17H11.8994V18C11.8994 18.2652 12.0048 18.5196 12.1923 18.7071C12.3798 18.8946 12.6342 19 12.8994 19C13.1646 19 13.419 18.8946 13.6065 18.7071C13.7941 18.5196 13.8994 18.2652 13.8994 18V17H14.8994C15.1646 17 15.419 16.8946 15.6065 16.7071C15.7941 16.5196 15.8994 16.2652 15.8994 16C15.8994 15.7348 15.7941 15.4804 15.6065 15.2929C15.419 15.1054 15.1646 15 14.8994 15H13.8994V14C13.8994 13.7348 13.7941 13.4804 13.6065 13.2929C13.419 13.1054 13.1646 13 12.8994 13C12.6342 13 12.3798 13.1054 12.1923 13.2929C12.0048 13.4804 11.8994 13.7348 11.8994 14V15H10.8994C10.6342 15 10.3798 15.1054 10.1923 15.2929C10.0048 15.4804 9.89941 15.7348 9.89941 16C9.89941 16.2652 10.0048 16.5196 10.1923 16.7071C10.3798 16.8946 10.6342 17 10.8994 17ZM19.8994 6H17.8994V5C17.8994 4.20435 17.5833 3.44129 17.0207 2.87868C16.4581 2.31607 15.6951 2 14.8994 2H10.8994C10.1038 2 9.3407 2.31607 8.77809 2.87868C8.21548 3.44129 7.89941 4.20435 7.89941 5V6H5.89941C5.10376 6 4.3407 6.31607 3.77809 6.87868C3.21548 7.44129 2.89941 8.20435 2.89941 9V19C2.89941 19.7956 3.21548 20.5587 3.77809 21.1213C4.3407 21.6839 5.10376 22 5.89941 22H19.8994C20.6951 22 21.4581 21.6839 22.0207 21.1213C22.5833 20.5587 22.8994 19.7956 22.8994 19V9C22.8994 8.20435 22.5833 7.44129 22.0207 6.87868C21.4581 6.31607 20.6951 6 19.8994 6ZM9.89941 5C9.89941 4.73478 10.0048 4.48043 10.1923 4.29289C10.3798 4.10536 10.6342 4 10.8994 4H14.8994C15.1646 4 15.419 4.10536 15.6065 4.29289C15.7941 4.48043 15.8994 4.73478 15.8994 5V6H9.89941V5ZM20.8994 19C20.8994 19.2652 20.7941 19.5196 20.6065 19.7071C20.419 19.8946 20.1646 20 19.8994 20H5.89941C5.6342 20 5.37984 19.8946 5.19231 19.7071C5.00477 19.5196 4.89941 19.2652 4.89941 19V12H20.8994V19ZM20.8994 10H4.89941V9C4.89941 8.73478 5.00477 8.48043 5.19231 8.29289C5.37984 8.10536 5.6342 8 5.89941 8H19.8994C20.1646 8 20.419 8.10536 20.6065 8.29289C20.7941 8.48043 20.8994 8.73478 20.8994 9V10Z"
                  fill="#20409B"
                />
              </svg>
              <span class="ml-1 txt-pri robo-16-500">
                {{ $t("obj_health_records.lbl_select_cr") }}
              </span>
            </label>
            <v-select
              class="select-box"
              :options="(consulting && consulting) || []"
              v-model="selected_consulting"
              :disabled="loading_consulting || loading_time"
              label="name"
              :placeholder="$t('obj_health_records.lbl_select_room')"
              @search="searchConsulting"
              :filterable="false"
              @input="selectedConsulting"
            >
              <div slot="no-options">
                <span v-if="!loading_consulting">
                  {{ $t("obj_health_records.lbl_no_cr_found") }}
                </span>
              </div>
              <template #list-footer>
                <li class="text-center mt-1">
                  <span
                    class="text-secondary spinner-border spinner-border-sm mr-2"
                    role="status"
                    v-if="loading_consulting"
                  ></span>
                </li>
              </template>
            </v-select>
          </div>
          <div class="row mb-3">
            <div class="col-12 p-2">
              <div>
                <div
                  :class="form.method == 1 ? 'bg-pri' : 'bg-f4'"
                  class="radius-10 text-center p-3 cursor-pointer"
                  @click="form.method = 1"
                >
                  <img
                    src="../../../public/assets/images/icon/hospital.svg"
                    v-show="form.method != 1"
                    alt
                  />
                  <img
                    src="../../../public/assets/images/icon/white-hospital.svg"
                    v-show="form.method == 1"
                    alt
                  />
                  <p
                    class="mb-0 robo-14-400 mt-1"
                    :class="form.method == 1 ? 'text-white' : ''"
                  >
                    {{ $t("obj_health_records.lbl_health_facilities") }}
                  </p>
                </div>
              </div>
            </div>
            <!-- <div class="col-6 p-2">
              <div>
                <div
                  :class="form.method == 2 ? 'bg-pri' : 'bg-f4'"
                  class="radius-10 text-center p-3 cursor-pointer"
                  @click="form.method = 2"
                >
                  <img
                    src="../../../public/assets/images/icon/blue-video.svg"
                    v-show="form.method != 2"
                    alt=""
                  />
                  <img
                    src="../../../public/assets/images/icon/white_video.svg"
                    v-show="form.method == 2"
                    alt=""
                  />
                  <p
                    class="mb-0 robo-14-400 mt-1"
                    :class="form.method == 2 ? 'text-white' : ''"
                  >
                    Tư vấn Online
                  </p>
                </div>
              </div>
            </div>-->
          </div>
          <div class="mb-3">
            <!-- <div class="d-flex align-items-center justify-content-between mb-1" v-if="form.method == 2">
                            <span class="robo-16-500 txt-pri">Đơn giá</span>
                            <span class="robo-24-500 text-red">{{distance.price}} VNĐ</span>
            </div>-->
            <div class="d-flex align-items-center" v-if="form.method == 2">
              <img
                src="../../../public/assets/images/icon/clock-blue.svg"
                alt
                class="mr-1"
              />
              <span class="robo-16-500 txt-pri">
                {{
                  $t("obj_health_records.fm_duration_minute", {
                    minute: distance.time,
                  })
                }}
              </span>
            </div>
          </div>
        </div>
        <div
          :class="selected_date ? 'col-md-8' : 'col-md-6'"
          v-show="selected_consulting"
        >
          <div class="mb-3 row">
            <div :class="selected_date ? 'col-md-8' : 'col-12'">
              <p class="mb-4 ml-3 robo-24-500 txt-black">
                {{ $t("obj_health_records.lbl_select_time") }}
              </p>
              <v-date-picker
                v-model="selected_date"
                @input="onChangeDate()"
                :min-date="new Date()"
                is-expanded
              />
            </div>
            <div class="col-4" v-show="selected_date">
              <label class="ml-2 mt-4 mb-2 robo-18-500 txt-black">
                {{ formatDMY(selected_date) }}
              </label>
              <div
                class="d-flex justify-content-center align-items-center py-5"
                v-if="loading_time"
              >
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="p-2 appt-time-slot">
                <div v-if="(!timeslots || !timeslots.length) && !loading_time">
                  <p class="text-center fs-16 fw-500">
                    {{ $t("obj_health_records.lbl_no_appt_empty") }}
                  </p>
                </div>
                <div
                  v-for="(slot, index) in timeslots"
                  :key="index"
                  @click="clickTime(slot)"
                >
                  <div
                    class="time-slot time-slot-df"
                    style
                    v-if="show_slot !== slot.id"
                  >
                    <p class="robo-20-500 mb-0 txt-pri">
                      {{ formatTime(slot.start_time) }}
                    </p>
                  </div>
                  <div
                    class="time-slot time-slot-mouseover row"
                    v-if="show_slot === slot.id"
                  >
                    <div class="col-6 pr-1">
                      <div
                        class="h-100 d-flex justify-content-center align-items-center time"
                      >
                        <p class="robo-20-500 mb-0 text-white">
                          {{ formatTime(slot.start_time) }}
                        </p>
                      </div>
                    </div>
                    <div class="col-6 pl-1">
                      <div
                        class="h-100 d-flex justify-content-center align-items-center bg-pri"
                        @click="selectedTime(slot)"
                      >
                        <p class="robo-20-500 mb-0 text-white">
                          {{ $t("obj_health_records.lbl_select") }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import moment from 'moment'
export default {
  name: 'AppointmentForm',
  props: ['appt_edit', 'disease'],
  components: { vSelect },
  computed: {},
  data () {
    return {
      moment,
      doctors: null,
      persons: null,
      diseases: null,
      form: {
        start_time: '',
        end_time: '',
        note: '',
        person_id: '',
        doctor_id: null,
        method: 1,
        price: 0
      },
      err_patient: '',
      err_doctor: '',
      err_disease: '',
      err_time: '',
      selected_doctor: null,
      selected_disease: null,
      selected_person: null,
      selected_consulting: null,
      loading_persons: false,
      loading_doctors: false,
      loading_diseases: false,
      loading_consulting: false,
      clinic: null,
      distance: {
        time: 15,
        price: 0
      },
      timeslots: [],
      choosed_time: null,
      choosed_date: null,
      selected_date: moment().locale('vi').toDate(),
      blocked_times: null,
      selected_time: null,
      show_slot: null,
      loading: false,
      search_person: '',
      consulting: null,
      loading_time: false
    }
  },
  watch: {
    disease: function (disease, old) {
      this.halderDisease()
    },
    timeslots: function (timeslots, old) {
      let self = this
      self.selected_time = null
      self.show_slot = null
    }
  },
  mounted () {
    this.halderDisease()
    this.getConsultingRooms()
    this.clinic = this.$globalClinic
  },
  methods: {
    halderDisease () {
      this.selected_disease = this.disease
      if (this.disease) {
        if (this.disease.person) {
          this.selected_person = this.disease.person
        }
      }
      this.selected_doctor = this.$user?.doctor
    },
    formatTime (item) {
      return window.moment(item).format('HH:mm')
    },
    async getConsultingRooms (kw) {
      this.loading_consulting = true
      const params = {
        limit: 50,
        doctor_id: this.$user?.doctor?.id
      }
      if (kw) {
        params.search = kw
      }
      try {
        let resp = await this.$rf
          .getRequest('DoctorRequest')
          .getConsultingRooms(params)
        this.consulting = resp.data.data
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        this.loading_consulting = false
      }
    },
    //
    onChangeDate () {
      let self = this
      if (self.selected_doctor) {
        self.timeslots = []
        self.choosed_time = null
        if (self.selected_date) self.getTimeSlot()
        // clear giờ đã chọn trước đây
        self.selected_time = null
      } else {
        self.err_doctor = this.$t('obj_health_records.lbl_no_select_doctor')
      }
    },
    async getTimeSlot () {
      let self = this
      const cr_id = self.selected_consulting?.id
      let params = {
        doctor_id: self.selected_doctor.id,
        date: moment(self.selected_date).format('YYYY-MM-DD HH:mm:ss')
      }
      this.loading_time = true
      await self.$rf
        .getRequest('AuthRequest')
        .getTimeSlot(cr_id, params)
        .then((resp) => {
          self.timeslots = resp.data
        })
        .finally(() => {
          self.loading_time = false
        })
    },
    clickTime (slot) {
      this.show_slot = slot.id
    },
    selectedTime (slot) {
      this.selected_time = slot
      this.submit()
    },
    //
    async submit () {
      if (!this.validate()) return
      let params = {
        disease: this.selected_disease,
        person: this.selected_person,
        doctor: this.selected_doctor,
        consulting: this.selected_consulting,
        time: this.selected_time,
        distance: this.distance,
        clinic: this.clinic,
        form: this.form
      }

      this.$emit('nextStep', params)
    },
    validate () {
      let self = this
      if (!self.selected_person) {
        self.err_patient = this.$t(
          'obj_health_records.lbl_please_select_patient'
        )
        return false
      } else if (!self.selected_disease) {
        self.err_disease = this.$t('obj_health_records.lbl_please_select_hr')
        return false
      } else if (!self.selected_doctor) {
        self.err_doctor = this.$t(
          'obj_health_records.lbl_please_select_doctor'
        )
        return false
      } else if (!self.selected_time) {
        self.err_time = this.$t('obj_health_records.lbl_please_select_time')
        return false
      } else {
        return true
      }
    },
    selectedConsulting () {
      this.getConsultingRooms()
      this.getTimeSlot()
      if (this.selected_consulting) {
        this.distance = {
          time: this.selected_consulting?.slot_duration,
          price: this.selected_consulting?.slot_price
        }
      }
    },
    searchConsulting (kw) {
      this.getConsultingRooms(kw)
    },
    // Utils
    formatDMY (date) {
      return window.moment(date).format('DD/MM/YYYY')
    }
  }
}
</script>
<style lang="css" scoped>
.loading-item-box {
  height: 53px;
}

.bg-f4 {
  background-color: #f4f4f4;
}

.radius-10 {
  border-radius: 10px;
}

.vc-container {
  border: 0 !important;
}

.btn-close {
  background: url(../../../public/assets/images/icon/close-gray.svg) no-repeat
    center center/1.75rem auto !important;
}

.appt-time-slot {
  max-height: calc(100vh - 250px);
  overflow-y: auto;
}

.time-slot-df {
  border: solid 1px #20419b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.time-slot {
  height: 48px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 100%;
}

.time-slot-mouseover .time {
  background-color: #8a8a8a;
}

.select-box {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f4f4f4;
  border-radius: 5px;
  font-weight: bold;
}
</style>
