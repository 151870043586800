<template>
  <div class>
    <div class="row" v-if="data">
      {{ data.selected_consulting }}
      <div class="col-md-5 pt-2">
        <div class="mb-4">
          <el-tooltip :content="$t('Quay lại chọn bác sĩ - lịch hẹn')" placement="right">
            <div
              @click="backStep()"
              role="button"
              class="inline-block w-12 h-12 bg-white rounded-circle overflow-hidden"
            >
              <span
                class="flex w-12 h-12 align-middle border rounded-circle hover:bg-blue-100 transition-all duration-200"
              >
                <i class="el-icon-back m-auto text-2xl" />
              </span>
            </div>
          </el-tooltip>
        </div>
        <div class="flex items-center mb-3">
          <div class="w-8 text-center">
            <i
              v-if="data.form.method === APPOINTMENT_METHOD_STATUS.CODE.OFFLINE"
              class="el-icon-school text-3xl"
              style="color: #20409B"
            />
            <svg v-else viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.60077 6.70721C4.02093 7.28704 3.66699 8.33477 3.66699 10.3868V21.6134C3.66699 22.8724 3.9822 24.0196 4.66178 24.8348C5.3146 25.6179 6.41312 26.2268 8.28033 26.2268H16.707C18.7513 26.2268 19.8002 25.8732 20.3822 25.2923C20.9638 24.7119 21.3203 23.6635 21.3203 21.6134V10.3868C21.3203 8.33477 20.9664 7.28704 20.3866 6.70721C19.8067 6.12738 18.759 5.77344 16.707 5.77344H8.28033C6.22833 5.77344 5.1806 6.12738 4.60077 6.70721ZM3.18655 5.293C4.36005 4.1195 6.11899 3.77344 8.28033 3.77344H16.707C18.8683 3.77344 20.6273 4.1195 21.8008 5.293C22.9743 6.4665 23.3203 8.22544 23.3203 10.3868V21.6134C23.3203 23.7767 22.9702 25.535 21.7951 26.7079C20.6204 27.8803 18.8626 28.2268 16.707 28.2268H8.28033C5.9342 28.2268 4.22605 27.4356 3.12554 26.1154C2.05178 24.8273 1.66699 23.1678 1.66699 21.6134V10.3868C1.66699 8.22544 2.01305 6.4665 3.18655 5.293Z"
                fill="#20409B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M26.5996 10.0006C26.5994 10.0007 26.5999 10.0004 26.5996 10.0006L23.3203 12.3008V19.674L26.6012 21.9754L26.6037 21.9771C27.3639 22.5137 27.7743 22.4266 27.8993 22.3617C28.025 22.2964 28.3336 22.0091 28.3336 21.0741V10.9141C28.3336 9.98004 28.0254 9.69069 27.8974 9.62383C27.7696 9.55711 27.358 9.4711 26.5996 10.0006ZM28.8232 7.85102C29.8952 8.41083 30.3336 9.62147 30.3336 10.9141V21.0741C30.3336 22.3658 29.8956 23.5785 28.8213 24.1365C27.7468 24.6946 26.5046 24.3548 25.4517 23.6121C25.4513 23.6117 25.4508 23.6114 25.4503 23.6111L21.7461 21.0128C21.4792 20.8256 21.3203 20.5201 21.3203 20.1941V11.7808C21.3203 11.4548 21.4792 11.1493 21.7461 10.9621L25.4543 8.36096C26.5088 7.62436 27.751 7.29109 28.8232 7.85102Z"
                fill="#20409B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.333 11.668C14.7807 11.668 14.333 12.1157 14.333 12.668C14.333 13.2203 14.7807 13.668 15.333 13.668C15.8853 13.668 16.333 13.2203 16.333 12.668C16.333 12.1157 15.8853 11.668 15.333 11.668ZM12.333 12.668C12.333 11.0111 13.6762 9.66797 15.333 9.66797C16.9899 9.66797 18.333 11.0111 18.333 12.668C18.333 14.3248 16.9899 15.668 15.333 15.668C13.6762 15.668 12.333 14.3248 12.333 12.668Z"
                fill="#20409B"
              />
            </svg>
          </div>
          <p class="fs-20 font-medium ml-2 mb-0" style="color: #20409B;">{{ getMethodStatus }}</p>
        </div>
        <div
          v-if="data.form.method === APPOINTMENT_METHOD_STATUS.CODE.OFFLINE"
          class="flex items-center mb-3"
        >
          <div class="w-8 text-center">
            <i class="el-icon-location-information text-3xl" style="color: #20409B;" />
          </div>
          <p class="fs-20 font-medium ml-2 mb-0" style="color: #20409B;">{{ data.clinic.name }}</p>
        </div>
        <div class="flex items-center mb-3">
          <div class="w-8 text-center">
            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.61027 7.68792C4.83325 9.4648 4 12.4273 4 17.3487C4 22.27 4.83325 25.2325 6.61027 27.0094C8.38732 28.7863 11.3498 29.6192 16.2705 29.6192C21.192 29.6192 24.1549 28.7863 25.9321 27.0094C27.7093 25.2325 28.5425 22.2701 28.5425 17.3487C28.5425 12.4273 27.7093 9.46482 25.9321 7.68795C24.1549 5.91104 21.192 5.07812 16.2705 5.07812C11.3498 5.07812 8.38732 5.91103 6.61027 7.68792ZM5.19612 6.27365C7.5663 3.90368 11.2391 3.07812 16.2705 3.07812C21.3027 3.07812 24.9758 3.90367 27.3462 6.27362C29.7166 8.64362 30.5425 12.3164 30.5425 17.3487C30.5425 22.3809 29.7166 26.0537 27.3462 28.4237C24.9758 30.7937 21.3027 31.6192 16.2705 31.6192C11.2391 31.6192 7.5663 30.7936 5.19612 28.4237C2.82593 26.0537 2 22.3809 2 17.3487C2 12.3164 2.82593 8.64364 5.19612 6.27365Z"
                fill="#20409B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.39453 12.4062C2.39453 11.854 2.84225 11.4062 3.39453 11.4062H29.1629C29.7152 11.4062 30.1629 11.854 30.1629 12.4062C30.1629 12.9585 29.7152 13.4062 29.1629 13.4062H3.39453C2.84225 13.4062 2.39453 12.9585 2.39453 12.4062Z"
                fill="#20409B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.5742 18.043C21.5742 17.4907 22.0219 17.043 22.5742 17.043H22.5871C23.1394 17.043 23.5871 17.4907 23.5871 18.043C23.5871 18.5953 23.1394 19.043 22.5871 19.043H22.5742C22.0219 19.043 21.5742 18.5953 21.5742 18.043Z"
                fill="#20409B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.2793 18.043C15.2793 17.4907 15.727 17.043 16.2793 17.043H16.2922C16.8445 17.043 17.2922 17.4907 17.2922 18.043C17.2922 18.5953 16.8445 19.043 16.2922 19.043H16.2793C15.727 19.043 15.2793 18.5953 15.2793 18.043Z"
                fill="#20409B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.9707 18.043C8.9707 17.4907 9.41842 17.043 9.9707 17.043H9.98358C10.5359 17.043 10.9836 17.4907 10.9836 18.043C10.9836 18.5953 10.5359 19.043 9.98358 19.043H9.9707C9.41842 19.043 8.9707 18.5953 8.9707 18.043Z"
                fill="#20409B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.5742 23.5547C21.5742 23.0024 22.0219 22.5547 22.5742 22.5547H22.5871C23.1394 22.5547 23.5871 23.0024 23.5871 23.5547C23.5871 24.107 23.1394 24.5547 22.5871 24.5547H22.5742C22.0219 24.5547 21.5742 24.107 21.5742 23.5547Z"
                fill="#20409B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.2793 23.5547C15.2793 23.0024 15.727 22.5547 16.2793 22.5547H16.2922C16.8445 22.5547 17.2922 23.0024 17.2922 23.5547C17.2922 24.107 16.8445 24.5547 16.2922 24.5547H16.2793C15.727 24.5547 15.2793 24.107 15.2793 23.5547Z"
                fill="#20409B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.9707 23.5547C8.9707 23.0024 9.41842 22.5547 9.9707 22.5547H9.98358C10.5359 22.5547 10.9836 23.0024 10.9836 23.5547C10.9836 24.107 10.5359 24.5547 9.98358 24.5547H9.9707C9.41842 24.5547 8.9707 24.107 8.9707 23.5547Z"
                fill="#20409B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.0068 1C22.5591 1 23.0068 1.44772 23.0068 2V6.66772C23.0068 7.22001 22.5591 7.66772 22.0068 7.66772C21.4546 7.66772 21.0068 7.22001 21.0068 6.66772V2C21.0068 1.44772 21.4546 1 22.0068 1Z"
                fill="#20409B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.5488 1C11.1011 1 11.5488 1.44772 11.5488 2V6.66772C11.5488 7.22001 11.1011 7.66772 10.5488 7.66772C9.99654 7.66772 9.54883 7.22001 9.54883 6.66772V2C9.54883 1.44772 9.99654 1 10.5488 1Z"
                fill="#20409B"
              />
            </svg>
          </div>
          <p class="fs-20 font-medium ml-2 mb-0" style="color: #20409B;">
            {{ formatHMDMY(data.selectedDate + " "
            + data.time.Start) }}
          </p>
        </div>
        <div class="flex items-center mb-3">
          <div class="w-8 text-center">
            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.0417 3.9593C9.37248 3.9593 3.9593 9.37248 3.9593 16.0417C3.9593 22.7109 9.37248 28.124 16.0417 28.124C22.7109 28.124 28.124 22.7109 28.124 16.0417C28.124 9.37248 22.7109 3.9593 16.0417 3.9593ZM2 16.0417C2 8.29039 8.29039 2 16.0417 2C23.7929 2 30.0833 8.29039 30.0833 16.0417C30.0833 23.7929 23.7929 30.0833 16.0417 30.0833C8.29039 30.0833 2 23.7929 2 16.0417Z"
                fill="#20409B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5588 9.19531C16.0998 9.19531 16.5384 9.63392 16.5384 10.175V15.5304C16.5384 15.731 16.615 16.0217 16.7853 16.3203C16.9556 16.6189 17.1667 16.8326 17.3383 16.9343L17.3409 16.9358L17.3409 16.9358L21.3901 19.3523C21.8547 19.6296 22.0066 20.231 21.7293 20.6956C21.452 21.1602 20.8506 21.312 20.386 21.0348L16.3394 18.6198C16.3389 18.6196 16.3385 18.6193 16.338 18.619C15.8049 18.3027 15.3764 17.8049 15.0833 17.2909C14.7899 16.7764 14.5791 16.1527 14.5791 15.5304V10.175C14.5791 9.63392 15.0177 9.19531 15.5588 9.19531Z"
                fill="#20409B"
              />
            </svg>
          </div>
          <p
            class="fs-20 font-medium ml-2 mb-0"
            style="color: #20409B;"
          >{{ data.distance.time }} phút</p>
        </div>
      </div>
      <div class="col-md-7 border-left pl-3">
        <p class="robo-24-500 txt-black mb-4">Thông tin Lịch Hẹn</p>
        <div class="flex flex-col lg:flex-row items-start mb-3">
          <div class="flex items-center mb-1 w-1/2">
            <div class="w-7 text-center">
              <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.2751 21.6128C8.76475 22.6041 8.03479 23.8014 8.03479 24.8482C8.03479 25.3513 8.1968 25.722 8.48929 26.0345C8.80456 26.3713 9.31333 26.6816 10.0549 26.9375C11.5506 27.4536 13.688 27.6474 16.1264 27.6474C18.5762 27.6474 20.7125 27.4467 22.2036 26.9248C22.9427 26.6661 23.4496 26.3533 23.7639 26.0143C24.0561 25.6992 24.2179 25.3255 24.2179 24.8207C24.2179 23.7779 23.486 22.5874 21.971 21.6012C20.4843 20.6335 18.4016 19.983 16.1264 19.983C13.8391 19.983 11.7577 20.6397 10.2751 21.6128ZM9.13978 19.8831C10.9829 18.6734 13.4645 17.9141 16.1264 17.9141C18.7736 17.9141 21.2539 18.6658 23.0996 19.8673C24.917 21.0502 26.2869 22.7958 26.2869 24.8207C26.2869 25.8492 25.9266 26.7248 25.281 27.4211C24.6575 28.0935 23.8108 28.5543 22.887 28.8776C21.0524 29.5197 18.6256 29.7164 16.1264 29.7164C13.6423 29.7164 11.2167 29.5271 9.37998 28.8933C8.45531 28.5742 7.60552 28.1179 6.97878 27.4483C6.32926 26.7544 5.96582 25.8789 5.96582 24.8482C5.96582 22.8273 7.32434 21.0747 9.13978 19.8831Z"
                  fill="#20409B"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.23246 8.84727C9.2334 5.04644 12.3156 1.96484 16.1166 1.96484C19.9185 1.96484 22.9996 5.04767 22.9996 8.84902C22.9996 12.6507 19.9183 15.732 16.1166 15.732H16.0763L16.0719 15.732C12.2817 15.7191 9.22056 12.6371 9.23246 8.84727ZM16.1166 4.03381C13.4577 4.03381 11.3014 6.19005 11.3014 8.84902V8.85253C11.2924 11.5002 13.4301 13.6532 16.0773 13.663H16.1157C18.7747 13.663 20.9306 11.508 20.9306 8.84902C20.9306 6.18977 18.7753 4.03381 16.1166 4.03381Z"
                  fill="#20409B"
                />
              </svg>
            </div>
            <div class="fs-16 ml-2">{{ $t('Bệnh nhân') }}</div>
          </div>
          <div class="fs-20 font-medium txt-pri lg:ml-auto text-right">
            <span>{{ data.person.name }}</span>
            <span>-</span>
            <span class="capitalize" v-if="data.person.birthday">
              {{ mdtUtils.getAges(new Date(data.person.birthday))
              }}
            </span>
            <span class="capitalize" v-else>{{ mdtUtils.getAges(data.person.age) }}</span>
          </div>
        </div>

        <div v-if="data.person.address" class="flex flex-col lg:flex-row items-start mb-3">
          <div class="flex items-center mb-1 w-1/4">
            <div class="w-7 text-center">
              <i class="el-icon-location-information text-2xl" style="color: #20409B;" />
            </div>
            <div class="fs-16 ml-2">{{ $t('Địa chỉ') }}</div>
          </div>
          <div class="fs-20 font-medium txt-pri lg:ml-auto text-right">
            <span>{{ data.person.address }}</span>
          </div>
        </div>

        <div v-if="data.disease" class="flex flex-col lg:flex-row items-start mb-3">
          <div class="flex items-center mb-1 w-1/4">
            <div class="w-7 text-center">
              <svg viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.6869 27.7466C17.2335 27.9066 16.4869 27.9066 16.0335 27.7466C12.1669 26.4266 3.52686 20.92 3.52686 11.5866C3.52686 7.46663 6.84686 4.1333 10.9402 4.1333C13.3669 4.1333 15.5135 5.30663 16.8602 7.11997C18.2069 5.30663 20.3669 4.1333 22.7802 4.1333C26.8735 4.1333 30.1935 7.46663 30.1935 11.5866C30.1935 20.92 21.5535 26.4266 17.6869 27.7466Z"
                  stroke="#20419B"
                  stroke-width="2.06897"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.98877 14.2405H11.4873C12.4873 14.2405 13.6073 15.0005 13.9807 15.9338L15.5007 19.7738C15.8473 20.6405 16.394 20.6405 16.7407 19.7738L19.794 12.0271C20.0873 11.2805 20.634 11.2538 21.0073 11.9605L22.394 14.5871C22.8073 15.3738 23.874 16.0138 24.754 16.0138H29.5274"
                  stroke="#20419B"
                  stroke-width="2.06897"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="fs-16 ml-2">{{ $t('Hồ sơ sức khỏe') }}</div>
          </div>
          <div class="fs-20 font-medium txt-pri lg:ml-auto text-right">
            <span>{{ data.disease.diseases_name }}</span>
          </div>
        </div>

        <div class="flex flex-col lg:flex-row items-start mb-3">
          <div class="flex items-center mb-1 w-1/4">
            <div class="w-7 text-center">
              <i class="el-icon-first-aid-kit text-2xl" style="color: #20409B" />
            </div>
            <div class="fs-16 ml-2">{{ $t('Phòng Tư vấn') }}</div>
          </div>
          <div class="fs-20 font-medium txt-pri lg:ml-auto text-right">
            <span>{{ data.consulting.ConsultingRoom.Name }}</span>
          </div>
        </div>

        <div class="flex flex-col lg:flex-row items-start mb-3">
          <div class="flex items-center mb-1 w-1/4">
            <div class="w-7 text-center">
              <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21.998 11V5C21.998 3.346 20.652 2 18.998 2H12.998C11.344 2 9.99805 3.346 9.99805 5V11C9.99805 14.309 12.689 17 15.998 17C19.307 17 21.998 14.309 21.998 11ZM11.998 5C11.998 4.449 12.447 4 12.998 4H18.998C19.549 4 19.998 4.449 19.998 5V6H11.998V5ZM11.998 11V8H19.998V11C19.998 11.179 19.968 11.35 19.945 11.523C18.864 10.564 17.467 10 15.998 10C14.529 10 13.132 10.564 12.051 11.523C12.028 11.35 11.998 11.179 11.998 11ZM15.998 12C17.22 12 18.339 12.554 19.093 13.502C18.359 14.408 17.252 15 15.998 15C14.744 15 13.637 14.408 12.903 13.502C13.657 12.554 14.776 12 15.998 12V12Z"
                  fill="#20409B"
                />
                <path
                  d="M23 18.0039H9C6.243 18.0039 4 20.2469 4 23.0039V29.0039C4 29.5559 4.448 30.0039 5 30.0039H27C27.552 30.0039 28 29.5559 28 29.0039V23.0039C28 20.2469 25.757 18.0039 23 18.0039ZM26 28.0039H6V23.0039C6 21.3499 7.346 20.0039 9 20.0039H11V21.1459C9.28 21.5929 8 23.1459 8 25.0039V26.0039C8 26.5559 8.448 27.0039 9 27.0039H10C10.552 27.0039 11 26.5559 11 26.0039C11 25.4519 10.552 25.0039 10 25.0039C10 23.9009 10.897 23.0039 12 23.0039C13.103 23.0039 14 23.9009 14 25.0039C13.448 25.0039 13 25.4519 13 26.0039C13 26.5559 13.448 27.0039 14 27.0039H15C15.552 27.0039 16 26.5559 16 26.0039V25.0039C16 23.1459 14.72 21.5929 13 21.1459V20.0039H20V22.2809C19.405 22.6269 19 23.2649 19 24.0039C19 25.1089 19.895 26.0039 21 26.0039C22.105 26.0039 23 25.1089 23 24.0039C23 23.2659 22.595 22.6279 22 22.2809V20.0039H23C24.654 20.0039 26 21.3499 26 23.0039V28.0039Z"
                  fill="#20409B"
                />
              </svg>
            </div>
            <div class="fs-16 ml-2">{{ $t('Bác sĩ') }}</div>
          </div>
          <div class="fs-20 font-medium txt-pri lg:ml-auto text-right">
            <span>{{ data.consulting.Doctor.User.name }}</span>
          </div>
        </div>

        <div class="flex flex-col items-start mb-3">
          <div class="flex items-center mb-1 w-1/4">
            <div class="w-7 text-center">
              <i class="el-icon-chat-square text-2xl" style="color: #20409B" />
            </div>
            <div class="fs-16 ml-2">{{ $t('Lý do khám') }}</div>
          </div>
          <div class="w-full">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 6 }"
              v-model="data.appt_reason"
              autocomplete="off"
            ></el-input>
          </div>
        </div>

        <div class="flex flex-col lg:flex-row items-start mb-3">
          <div class="flex items-center mb-1 w-1/4">
            <div class="w-7 text-center">
              <i class="el-icon-money text-2xl" style="color: #20409B" />
            </div>
            <div class="fs-16 ml-2">{{ $t('Đơn giá') }}</div>
          </div>
          <div class="fs-20 font-medium text-red-500 lg:ml-auto">
            <span>
              {{ data.distance.price
              ? formatCurrency(data.distance.price)
              : $t('Hỗ trợ') }}
            </span>
          </div>
        </div>

        <div class="flex flex-col items-start mb-3">
          <div class="flex items-center mb-1 w-1/4">
            <div class="w-7 text-center">
              <i class="el-icon-chat-square text-2xl" style="color: #20409B" />
            </div>
            <div class="fs-16 ml-2">{{ $t('Ghi chú') }}</div>
          </div>
          <div class="w-full">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 6 }"
              v-model="data.note"
              autocomplete="off"
            ></el-input>
          </div>
        </div>

        <div class="pt-1">
          <button
            v-if="!isUpdate"
            class="btn w-100 bg-pri bd-pri text-white cursor-pointer uppercase"
            @click="createAppt"
            :disabled="loading"
          >
            <span class="spinner-border spinner-border-sm mr-2" role="status" v-if="loading"></span>
            <span>Tạo lịch hẹn</span>
          </button>
          <button
            v-else
            class="btn w-100 bg-pri bd-pri text-white cursor-pointer uppercase"
            @click="updateAppt"
            :disabled="loading"
          >
            <span class="spinner-border spinner-border-sm mr-2" role="status" v-if="loading"></span>
            <span>Cập nhật lịch hẹn</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { APPOINTMENT_METHOD_STATUS } from '../../../utils/constants'
import mdtUtils from '../../../utils/mdtUtils'

export default {
  name: 'AppointmentConfirm',
  props: {
    data: {
      type: Object,
      required: true
    },
    returnRoute: {
      type: Object
    },
    isUpdate: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      mdtUtils,
      APPOINTMENT_METHOD_STATUS
    }
  },
  computed: {
    getMethodStatus () {
      const methodStatus = [
        '',
        this.$t('schedule_page.lbl_type_workspace'),
        this.$t('schedule_page.lbl_type_online')
      ]
      return methodStatus[this.data?.form?.method]
    }
  },
  methods: {
    backStep () {
      this.$emit('backStep')
    },
    setParams () {
      const self = this
      let params = self.data?.form

      params = {
        ...params,
        appt_reason: self.data.appt_reason,
        note: self.data.note
      }

      if (self.data?.person) params.person_id = self.data?.person?.id
      if (self.data?.consulting) {
        params.cr_id = self.data?.consulting.ConsultingRoom.ID
      }
      if (self.data?.disease) params.person_diseases_id = self.data?.disease?.id
      if (self.data?.doctor) params.doctor_id = self.data?.consulting.DoctorID
      if (self.data?.time) {
        params.start_time = window
          .moment(self.data.selectedDate + ' ' + self.data.time.Start)
          .format('YYYY-MM-DD HH:mm:ss')
        params.end_time = window
          .moment(self.data.selectedDate + ' ' + self.data.time.End)
          .format('YYYY-MM-DD HH:mm:ss')
        params.line_number = self.data.time.LineNumber
      }
      if (self.data?.distance) {
        params.slot_price = self.data?.distance.price
        params.slot_duration = self.data?.distance.time
      }
      if (self.data?.clinic) params.clinic_id = self.data?.clinic?.id
      if (self.data?.hr) params.health_record_id = self.data?.hr?.id
      if (self.data?.time?.TimeSlotID) {
        params.time_slot_id = self.data?.time?.TimeSlotID
      }

      const transferParams = {
        WorkspaceId: self.data?.consulting.ConsultingRoom.ClinicID,
        CrId: params.cr_id,
        DoctorID: params.doctor_id,
        StartTime: params.start_time,
        EndTime: params.end_time,
        SlotPrice: params.slot_price,
        SlotDuration: params.slot_duration,
        Reason: params.appt_reason,
        PersonId: params.person_id,
        Method: params.method,
        LineNumber: params.line_number,
        TimeSlotId: params.time_slot_id
      }
      return transferParams
    },
    async createAppt () {
      let self = this
      self.loading = true
      let params = self.setParams()
      if (this.$route.params?.id) {
        params.CurrentPatientVisitID = +this.$route.params?.id
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .postAppts(params)
        .then(resp => {
          console.log(resp)
          self.$toast.open({
            message: 'Tạo lịch hẹn thành công',
            type: 'success'
          })
          setTimeout(() => {
            self.loading = false
            if (self.$route.query?.cr_id || self.$route.query?.person_id) {
              this.$emit('done')
            } else {
              // self.$router.push({
              //   ...this.returnRoute,
              //   params: { id: resp?.data?.data?.id }
              // })
              if (this.$route.name === 'PatientVisitManagement') { this.$emit('success') } else this.$router.push({ name: 'PatientVisitManagement' })
            }
          }, 500)
        })
        .catch(e => {
          self.loading = false
          console.log(e)
        })
    },
    async updateAppt () {
      let self = this
      self.loading = true
      let params = self.setParams()

      if (!params) return

      await self.$rf
        .getRequest('DoctorRequest')
        .updateAppts(self.data?.id, params)
        .then(resp => {
          self.$toast.open({
            message: 'Cập nhật lịch hẹn thành công',
            type: 'success'
          })
        })
        .catch(e => {
          self.loading = false
          console.log(e)
        })
        .finally(_ => {
          self.$emit('refresh')
          self.$emit('hideModal')
        })
    },
    formatHMDMY (dt) {
      return window.moment(dt).format('HH:mm DD/MM/YYYY')
    },
    formatCurrency (str) {
      return str
        ? str.toLocaleString('it-IT', { style: 'currency', currency: 'VND' })
        : '0'
    }
  }
}
</script>
<style lang="css" scoped></style>
